import React from "react";

const Apply7 = ({
  goToNextPage,
  goToPreviousPage,
  formData,
  setFormData,
  formError,
  setFormError,
  motherOtherReligion,
  setMotherOtherReligion,
}) => {
  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setFormError({
      ...formError,
      [event.target.name]: event.target.value ? "" : "This Field is required",
    });
  };

  const handleMotherReligion = (event) => {
    if (event.target.value !== "other") {
      setFormData((prevData) => ({
        ...prevData,
        motherReligion: event.target.value,
        motherOtherReligion: "",
      }));
      setMotherOtherReligion(false);
    } else if (event.target.value === "other") {
      setFormData((prevData) => ({
        ...prevData,
        motherOtherReligion: "",
        motherReligion: event.target.value,
      }));
      setMotherOtherReligion(true);
    }
  };
  const handleNextClick = (event) => {
    event.preventDefault();
    let isFormValid = true;

    if (!formData.motherSurname) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        motherSurname: "Mother's Surname is required",
      }));
      console.log("2");
      isFormValid = false;
    }
    if (!formData.motherFirstname) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        motherFirstname: "Mother's First name is required",
      }));
      console.log("3");
      isFormValid = false;
    }
    if (!formData.motherMiddlename) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        motherMiddlename: "Mother's Midddle name is required",
      }));
      console.log("4");
      isFormValid = false;
    }
    if (!formData.motherReligion) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        motherReligion: "Mother's religion is required",
      }));
      isFormValid = false;
    }
    if (motherOtherReligion) {
      if (!formData.motherOtherReligion) {
        setFormError((prevErrors) => ({
          ...prevErrors,
          motherOtherReligion: "Mother's religion is required",
        }));
        isFormValid = false;
      }
    }
    if (!formData.motherPhoneNumber) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        motherPhoneNumber: "mother's Phone number is required",
      }));
      console.log("5");
      isFormValid = false;
    }
    if (!formData.motherEmail) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        motherEmail: "mother's Email Address is required",
      }));
      isFormValid = false;
    }
    if (!formData.motherWorkPlace) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        motherWorkPlace: "mother's workplace is required",
      }));
      isFormValid = false;
    }

    if (!isFormValid) {
      console.log("mother Details Error");
      return;
    }
    console.log(formData);
    goToNextPage();
  };
  return (
    <div className="row mt-1">
      <div className="col-xl-4 col-lg-6 col-md-10 my-2">
        <div className="customCard userHomeStatus">
          <div className="d-flex flex-column align-items-start">
            <h6 className="mt-2 title">NEW APPLICATION</h6>
            <div className="mt-4 w-100">
              <form action="">
                <div className="form-group mb-2">
                  <label htmlFor="motherSurname" className="mb-2">
                    Mother's Surname{" "}
                    {formError.motherSurname && (
                      <small className="px-1 py-2 text-danger">
                        {formError.motherSurname}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <input
                      id="motherSurname"
                      type="text"
                      name="motherSurname"
                      className="w-100 px-3 py-1 inputElement bg-white"
                      value={formData.motherSurname}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </div>
                </div>

                <div className="form-group mb-2">
                  <label htmlFor="motherFirstname" className="mb-2">
                    Mother's First Name{" "}
                    {formError.motherFirstname && (
                      <small className="px-1 py-2 text-danger">
                        {formError.motherFirstname}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <input
                      id="motherFirstname"
                      type="text"
                      name="motherFirstname"
                      className="py-1 w-100 px-3 inputElement bg-white"
                      value={formData.motherFirstname}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </div>
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="motherMiddlename" className="mb-2">
                    Mother's Middle Name{" "}
                    {formError.motherMiddlename && (
                      <small className="px-1 py-2 text-danger">
                        {formError.motherMiddlename}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <input
                      id="motherMiddlename"
                      type="text"
                      name="motherMiddlename"
                      className="py-1 w-100 px-3 inputElement bg-white"
                      value={formData.motherMiddlename}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </div>
                </div>
                <div className="form-group mb-2 d-flex flex-column align-items-start justify-content-between">
                  <label htmlFor="motherMaritalStatus" className="mb-2">
                    Mother's Marital Status{" "}
                    {formError.motherMaritalStatus && (
                      <small className="px-1 py-2 text-danger">
                        {formError.motherMaritalStatus}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <select
                      className="w-100 inputElement px-2 py-1"
                      name="motherMaritalStatus"
                      id="motherMaritalStatus"
                      defaultValue={formData.motherMaritalStatus}
                      onChange={(event) => handleInputChange(event)}
                    >
                      <option value="">Select Marital Status</option>
                      <option value="single">Single</option>
                      <option value="married">Married</option>
                      <option value="divorced">Divorced</option>
                      <option value="widowed">Widowed</option>
                      <option value="separated">Separated</option>
                      <option value="deceased">Deceased</option>
                    </select>
                    <div className="input-group-postpend">
                      <span
                        className="input-group-text h-100 position-absolute"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          zIndex: 10,
                          transform: "translateX(-1.75rem)",
                        }}
                      >
                        <i>
                          <svg
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.2021 0.450301L7.75177 13.3546L0.301465 0.450301L15.2021 0.450301Z"
                              fill="#D9D9D9"
                            ></path>
                          </svg>
                        </i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-2 d-flex flex-column align-items-start justify-content-between">
                  <label htmlFor="motherReligion" className="mb-2">
                    Mother's Religion{" "}
                    {formError.motherReligion && (
                      <small className="px-1 py-2 text-danger">
                        {formError.motherReligion}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <select
                      className="w-100 inputElement px-2 py-1"
                      name="motherReligion"
                      id="motherReligion"
                      defaultValue={formData.motherReligion}
                      onChange={(event) => handleMotherReligion(event)}
                    >
                      <option value="">Select Religion</option>
                      <option value="anglican">Anglican</option>
                      <option value="baptist">Baptist</option>
                      <option value="catholic">Catholic</option>
                      <option value="methodist">Methodist</option>
                      <option value="islam">Islam</option>
                      <option value="pentecostal">Pentecostal</option>
                      <option value="other">Other</option>
                    </select>
                    <div className="input-group-postpend">
                      <span
                        className="input-group-text h-100 position-absolute"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          zIndex: 10,
                          transform: "translateX(-1.75rem)",
                        }}
                      >
                        <i>
                          <svg
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.2021 0.450301L7.75177 13.3546L0.301465 0.450301L15.2021 0.450301Z"
                              fill="#D9D9D9"
                            ></path>
                          </svg>
                        </i>
                      </span>
                    </div>
                  </div>
                </div>
                {motherOtherReligion && (
                  <div className="form-group mb-3 d-flex flex-column align-items-start justify-content-between">
                    <label htmlFor="motherOtherReligion" className="mb-2">
                      Type In Mother's Religion{" "}
                      {formError.motherOtherReligion && (
                        <small className="px-1 py-2 text-danger">
                          {formError.motherOtherReligion}
                        </small>
                      )}
                    </label>
                    <div className="input-group">
                      <input
                        id="motherOtherReligion"
                        type="text"
                        name="motherOtherReligion"
                        className="py-1 w-100 px-3 inputElement bg-white"
                        value={formData.motherOtherReligion}
                        onChange={(event) => handleInputChange(event)}
                      />
                      <div className="input-group-postpend">
                        <span
                          className="input-group-text h-100 position-absolute"
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            zIndex: 10,
                            transform: "translateX(-1.75rem)",
                          }}
                        >
                          <i>
                            <svg
                              width="16"
                              height="14"
                              viewBox="0 0 16 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15.2021 0.450301L7.75177 13.3546L0.301465 0.450301L15.2021 0.450301Z"
                                fill="#D9D9D9"
                              ></path>
                            </svg>
                          </i>
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <div className="form-group mb-2">
                  <label htmlFor="motherPhoneNumber" className="mb-2">
                    Mother's Phone number{" "}
                    {formError.motherPhoneNumber && (
                      <small className="px-1 py-2 text-danger">
                        {formError.motherPhoneNumber}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <input
                      id="motherPhoneNumber"
                      type="number"
                      inputMode="numeric"
                      name="motherPhoneNumber"
                      className="py-1 w-100 px-2 inputElement bg-white applicant-phone"
                      value={formData.motherPhoneNumber}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </div>
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="motherEmail" className="mb-2">
                    Mother's Email address{" "}
                    {formError.motherEmail && (
                      <small className="px-1 py-2 text-danger">
                        {formError.motherEmail}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <input
                      id="motherEmail"
                      type="text"
                      name="motherEmail"
                      className="py-1 w-100 px-2 inputElement bg-white"
                      value={formData.motherEmail}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </div>
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="motherWorkPlace" className="mb-2">
                    Mother's Work Place{" "}
                    {formError.motherWorkPlace && (
                      <small className="px-1 py-2 text-danger">
                        {formError.motherWorkPlace}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <input
                      id="motherWorkPlace"
                      type="text"
                      name="motherWorkPlace"
                      className="py-1 w-100 px-2 inputElement bg-white"
                      value={formData.motherWorkPlace}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="form-group mt-2">
                    <input
                      type="button"
                      name="btnPrev"
                      className="btnPrev"
                      value="Back"
                      onClick={goToPreviousPage}
                    />
                  </div>
                  <div className="form-group mt-2">
                    <input
                      type="button"
                      name="btnNext"
                      className="btnNext"
                      value="Next"
                      onClick={(event) => handleNextClick(event)}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Apply7;
