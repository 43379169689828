import React, { useState } from "react";

const Apply8 = ({
  goToNextPage,
  goToPreviousPage,
  formData,
  setFormData,
  formError,
  setFormError,
  doHaveSisterInLagoon,
  setdoHaveSisterInLagoon,
}) => {
  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setFormError({
      ...formError,
      [event.target.name]: event.target.value ? "" : "This Field is required",
    });
  };

  const handleHaveSister = (event) => {
    handleInputChange(event);
    if (event.target.value === "yes") {
      setdoHaveSisterInLagoon(true);
    } else if (event.target.value === "no") {
      setdoHaveSisterInLagoon(false);
    }
  };

  const handleNextClick = (event) => {
    event.preventDefault();
    let isFormValid = true;

    if (!formData.haveSisterInLagoon) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        haveSisterInLagoon: "Please answer this question",
      }));
      isFormValid = false;
    }
    if (doHaveSisterInLagoon) {
      if (!formData.eldestSisterName) {
        setFormError((prevErrors) => ({
          ...prevErrors,
          eldestSisterName: "Answer is required",
        }));
        isFormValid = false;
      }
      if (!formData.sisterClass) {
        setFormError((prevErrors) => ({
          ...prevErrors,
          sisterClass: "Answer is required",
        }));
        isFormValid = false;
      }
    }

    if (!isFormValid) {
      console.log("mother Details Error");
      return;
    }
    console.log(formData);
    goToNextPage();
  };

  return (
    <div>
      <div className="row mt-1">
        <div className="col-xl-4 col-md-6 my-2">
          <div className="customCard userHomeStatus">
            <div className="d-flex flex-column align-items-start">
              <h6 className="mt-2 title">NEW APPLICATION</h6>
              <div className="mt-4 w-100">
                <form action="">
                  <div className="form-group mb-2 d-flex flex-column align-items-start justify-content-between">
                    <label htmlFor="haveSisterInLagoon" className="mb-2">
                      Does the applicant have a sister in Lagoon School?{" "}
                      {formError.haveSisterInLagoon && (
                        <small className="px-1 py-2 text-danger">
                          {formError.haveSisterInLagoon}
                        </small>
                      )}
                    </label>
                    <div className="input-group">
                      <select
                        className="w-100 inputElement px-2 py-1"
                        name="haveSisterInLagoon"
                        id="haveSisterInLagoon"
                        defaultValue={formData.haveSisterInLagoon}
                        onChange={(event) => handleHaveSister(event)}
                      >
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                      <div className="input-group-postpend">
                        <span
                          className="input-group-text h-100 position-absolute"
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            zIndex: 10,
                            transform: "translateX(-1.75rem)",
                          }}
                        >
                          <i>
                            <svg
                              width="16"
                              height="14"
                              viewBox="0 0 16 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15.2021 0.450301L7.75177 13.3546L0.301465 0.450301L15.2021 0.450301Z"
                                fill="#D9D9D9"
                              ></path>
                            </svg>
                          </i>
                        </span>
                      </div>
                    </div>
                  </div>
                  {doHaveSisterInLagoon && (
                    <React.Fragment>
                      <div className="form-group mb-2 d-flex flex-column align-items-start justify-content-between">
                        <label htmlFor="eldestSisterName" className="mb-2">
                          Name of the Oldest Sibling in Lagoon School?{" "}
                          {formError.eldestSisterName && (
                            <small className="px-1 py-2 text-danger">
                              {formError.eldestSisterName}
                            </small>
                          )}
                        </label>
                        <div className="input-group">
                          <input
                            id="eldestSisterName"
                            type="text"
                            name="eldestSisterName"
                            className="py-1 w-100 px-2 inputElement bg-white"
                            value={formData.eldestSisterName}
                            onChange={(event) => handleInputChange(event)}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="sisterClass" className="mb-2">
                          Class of Oldest sibling in Lagoon School?{" "}
                          {formError.sisterClass && (
                            <small className="px-1 py-2 text-danger">
                              {formError.sisterClass}
                            </small>
                          )}
                        </label>
                        <div className="input-group">
                          <select
                            className="w-100 inputElement px-2 py-2"
                            name="sisterClass"
                            id="sisterClass"
                            defaultValue={formData.sisterClass}
                            onChange={(event) => handleInputChange(event)}
                          >
                            <option value="">Select Class</option>
                            <option value="pry1">Pry 1</option>
                            <option value="pry2">Pry 2</option>
                            <option value="pry3">Pry 3</option>
                            <option value="pry4">Pry 4</option>
                            <option value="pry5">Pry 5</option>
                            <option value="pry6">Pry 6</option>
                            <option value="js1">JSS 1</option>
                            <option value="js2">JSS 2</option>
                            <option value="js3">JSS 3</option>
                            <option value="ss1">SS 2</option>
                            <option value="ss2">SS 2</option>
                            <option value="ss3">SS 3</option>
                          </select>
                          <div className="input-group-postpend">
                            <span
                              className="input-group-text h-100 position-absolute"
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                                zIndex: 10,
                                transform: "translateX(-1.75rem)",
                              }}
                            >
                              <i>
                                <svg
                                  width="16"
                                  height="14"
                                  viewBox="0 0 16 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M15.2021 0.450301L7.75177 13.3546L0.301465 0.450301L15.2021 0.450301Z"
                                    fill="#D9D9D9"
                                  ></path>
                                </svg>
                              </i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  <div className="d-flex justify-content-between">
                    <div className="form-group mt-2">
                      <input
                        type="button"
                        name="btnPrev"
                        className="btnPrev"
                        value="Back"
                        onClick={goToPreviousPage}
                      />
                    </div>
                    <div className="form-group mt-2">
                      <input
                        type="button"
                        name="btnNext"
                        className="btnNext"
                        value="Next"
                        onClick={(event) => handleNextClick(event)}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Apply8;
