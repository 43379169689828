import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import pdficon from "../../assets/images/pdficon.jpg";
import docxicon from "../../assets/images/docxicon.jpg";
import imageicon from "../../assets/images/imageicon.jpg";

const UserInfoModal = ({
  userInfoModal,
  handleClose,
  applicantData,
  handleDownloadAllDoc,
  handleApproveDocuments,
}) => {
  const [detailsType, setDetailsType] = useState("personalDetails");
  // Function to render different icons based on file extension
  const renderDocumentIcon = (fileName) => {
    const fileExtension = fileName.split(".").pop().toLowerCase();
    console.log(fileName);
    if (fileExtension === "pdf") {
      return <img src={pdficon} alt="PDF" className="file-icon" />;
    } else if (fileExtension === "docx" || fileExtension === "rtf") {
      return <img src={docxicon} alt="DOCX" className="file-icon" />;
    } else if (
      fileExtension === "jpg" ||
      fileExtension === "jpeg" ||
      fileExtension === "png" ||
      fileExtension === "PNG"
    ) {
      return (
        <img
          src={imageicon}
          alt="DOCX"
          className="file-icon"
          style={{ height: "100px" }}
        />
      );
    } else {
      // You can add more conditions for other file types here
      return <img src="/default-icon.png" alt="File" className="file-icon" />;
    }
  };
  return (
    <Modal
      show={userInfoModal}
      className="justify-content-center h-100 userInfoModal"
      onHide={handleClose}
    >
      <Modal.Header>
        <Modal.Title className="w-100">
          <h4 className="text-center">
            {applicantData.applicantSurname} {applicantData.applicantFirstname}
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <ul className="col-sm-11">
            <div className="row">
              <li className="col-2 navInfoTabs px-0">
                <button
                  className={`btn ${
                    detailsType === "personalDetails" ? "active" : ""
                  }`}
                  onClick={() => setDetailsType("personalDetails")}
                >
                  Personal Info
                </button>
              </li>
              <li className="col-2 navInfoTabs px-0">
                <button
                  className={`btn ${
                    detailsType === "fathersDetails" ? "active" : ""
                  }`}
                  onClick={() => setDetailsType("fathersDetails")}
                >
                  Father's Info
                </button>
              </li>
              <li className="col-2 navInfoTabs px-0">
                <button
                  className={`btn ${
                    detailsType === "mothersDetails" ? "active" : ""
                  }`}
                  onClick={() => setDetailsType("mothersDetails")}
                >
                  Mother's Info
                </button>
              </li>
              <li className="col-2 navInfoTabs px-0">
                <button
                  className={`btn ${
                    detailsType === "documentsDownload" ? "active" : ""
                  }`}
                  onClick={() => setDetailsType("documentsDownload")}
                >
                  Documents
                </button>
              </li>
              <li className="col-2 navInfoTabs px-0">
                <button
                  className={`btn ${
                    detailsType === "moreInfo" ? "active" : ""
                  }`}
                  onClick={() => setDetailsType("moreInfo")}
                >
                  More Info
                </button>
              </li>
            </div>
          </ul>
        </div>
        <div className="userDetails">
          {detailsType === "personalDetails" && (
            <div className="row personalDetailsTab">
              <div className="col-3 py-3">
                <h5 className="">Applicant's Title</h5>
                <p className="mb-0">{applicantData.applicantTitle}</p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Applicant's Surname</h5>

                <p className="mb-0">{applicantData.applicantSurname}</p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">
                  Applicant's <br></br> First name
                </h5>
                <p className="mb-0">{applicantData.applicantFirstname}</p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Applicant's Middle name</h5>
                <p className="mb-0">{applicantData.applicantMiddlename}</p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Applicant's D.O.B</h5>
                <p className="mb-0">{applicantData.applicantDob}</p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Gender</h5>
                <p className="mb-0">{applicantData.applicantGender}</p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Applicant's Religion</h5>
                <p className="mb-0">
                  {applicantData.applicantReligion === "other"
                    ? applicantData.applicantOtherReligion
                    : applicantData.applicantReligion}
                </p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Phone number</h5>
                <p className="mb-0">{applicantData.applicantPhonenumber}</p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Email Address</h5>
                <p className="mb-0">{applicantData.applicantEmail}</p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Home Address</h5>
                <p className="mb-0">{applicantData.applicantHomeAddress}</p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Area</h5>
                <p className="mb-0">
                  {applicantData.applicantAreaofLagos === "other"
                    ? applicantData.otherAreaofLagos
                    : applicantData.applicantAreaofLagos}
                </p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Nationality</h5>
                <p className="mb-0">
                  {applicantData.applicantNationality === "other"
                    ? applicantData.otherNationality
                    : applicantData.applicantNationality}
                </p>
              </div>
            </div>
          )}
          {detailsType === "fathersDetails" && (
            <div className="row fatherDetailsTab">
              <div className="col-3 py-3">
                <h5 className="">Father's Surname</h5>
                <p className="mb-0">{applicantData.fatherSurname}</p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Father's First name</h5>
                <p className="mb-0">{applicantData.fatherFirstname}</p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Father's Middle name</h5>
                <p className="mb-0">{applicantData.fatherMiddlename}</p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Marital Status</h5>
                <p className="mb-0">{applicantData.fatherMaritalStatus}</p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Father's Religion</h5>
                <p className="mb-0">
                  {applicantData.fatherReligion === "other"
                    ? applicantData.fatherOtherReligion
                    : applicantData.fatherReligion}
                </p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Phone number</h5>
                <p className="mb-0">{applicantData.fatherPhoneNumber}</p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Email Address</h5>
                <p className="mb-0">{applicantData.fatherEmail}</p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Workplace</h5>
                <p className="mb-0">{applicantData.fatherWorkPlace}</p>
              </div>
            </div>
          )}
          {detailsType === "mothersDetails" && (
            <div className="row motherDetailsTab">
              <div className="col-3 py-3">
                <h5 className="">Mother's Surname</h5>
                <p className="mb-0">{applicantData.motherSurname}</p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Mother's First name</h5>
                <p className="mb-0">{applicantData.motherFirstname}</p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Mother's Middle name</h5>
                <p className="mb-0">{applicantData.motherMiddlename}</p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Marital Status</h5>
                <p className="mb-0">{applicantData.motherMaritalStatus}</p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Mother's Religion</h5>
                <p className="mb-0">
                  {applicantData.motherReligion === "other"
                    ? applicantData.motherOtherReligion
                    : applicantData.motherReligion}
                </p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Phone number</h5>
                <p className="mb-0">{applicantData.motherPhoneNumber}</p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Email Address</h5>
                <p className="mb-0">{applicantData.motherEmail}</p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Workplace</h5>
                <p className="mb-0">{applicantData.motherWorkPlace}</p>
              </div>
            </div>
          )}
          {detailsType === "documentsDownload" && (
            <React.Fragment>
              <div>
                <button
                  className="btn btn-success"
                  onClick={() =>
                    handleApproveDocuments(applicantData.applicationId)
                  }
                >
                  Approve Documents
                </button>
              </div>
              <div className="row motherDetailsTab">
                {applicantData.documents.map((doc, index) => (
                  <div className="col-3 py-1 px-2 mx-2" key={index}>
                    <a
                      href={atob(doc.filePath)}
                      rel="noopener noreferrer"
                      style={{ height: "100px" }}
                      download={doc.fileName}
                      target="_blank"
                    >
                      {renderDocumentIcon(doc.fileName)}
                    </a>
                    <p className="mb-0">{doc.fileName}</p>
                  </div>
                ))}
                <div>
                  <button
                    className="btn btn-primary w-25 my-3 ml-2"
                    style={{ width: "60px", height: "40px", marginTop: "auto" }}
                    onClick={() =>
                      handleDownloadAllDoc(applicantData.applicationId)
                    }
                  >
                    Download All
                  </button>
                </div>
              </div>
            </React.Fragment>
          )}

          {detailsType === "moreInfo" && (
            <div className="row motherDetailsTab">
              <div className="col-3 py-3">
                <h5 className="">Present School</h5>
                <p className="mb-0">{applicantData.applicantPresentSchool}</p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Present Class</h5>
                <p className="mb-0">{applicantData.applicantPresentClass}</p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Class Applying To</h5>
                <p className="mb-0">{applicantData.applyingToClass}</p>
              </div>
              <div className="col-3 py-3">
                <h5 className="">Award Received</h5>
                <p className="mb-0">{applicantData.applicantAwardsReceived}</p>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <button className="btnNext small" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserInfoModal;
