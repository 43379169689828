import React from "react";

const Apply3 = ({
  goToNextPage,
  goToPreviousPage,
  formData,
  setFormData,
  formError,
  setFormError,
  disableNext,
  setDisableNext,
  applicantOtherReligion,
  setApplicantOtherReligion,
}) => {
  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setFormError({
      ...formError,
      [event.target.name]: event.target.value ? "" : "This Field is required",
    });
  };

  const handleApplicantReligion = (event) => {
    if (event.target.value !== "other") {
      setFormData((prevData) => ({
        ...prevData,
        applicantReligion: event.target.value,
        applicantOtherReligion: "",
      }));
      setApplicantOtherReligion(false);
    } else if (event.target.value === "other") {
      setFormData((prevData) => ({
        ...prevData,
        applicantOtherReligion: "",
        applicantReligion: event.target.value,
      }));
      setApplicantOtherReligion(true);
    }
  };

  // const handlePryFour = (event) => {
  //   const { value } = event.target;
  //   if (value === "yes") {
  //     setDisableNext(true);
  //   } else {
  //     setDisableNext(false);
  //     handleInputChange(event);
  //   }
  // };

  const handleNextClick = (event) => {
    event.preventDefault();
    let isFormValid = true;

    if (!formData.checkPryFour) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        checkPryFour: "Please answer this question",
      }));
      isFormValid = false;
    }
    if (!formData.applicantPresentClass) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        applicantPresentClass: "Class is required",
      }));
      isFormValid = false;
    }

    if (!formData.applicantPresentSchool) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        applicantPresentSchool: "Present School is required",
      }));
      isFormValid = false;
    }

    if (!formData.applyingToClass) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        applyingToClass: "What class are you applying to",
      }));
      isFormValid = false;
    }

    if (!formData.applicantReligion) {
      if (formData.applicantOtherReligion === "") {
        setFormError((prevErrors) => ({
          ...prevErrors,
          applicantReligion: "Religion is required",
        }));
        isFormValid = false;
      }
    }

    if (applicantOtherReligion) {
      if (!formData.applicantOtherReligion) {
        setFormError((prevErrors) => ({
          ...prevErrors,
          applicantOtherReligion: "This is required",
        }));
        isFormValid = false;
      }
    }

    if (!isFormValid) {
      console.log("Form Error Detected");
      return;
    }
    console.log(formData);
    goToNextPage();
  };
  return (
    <div className="row mt-4">
      <div className="col-xl-4 col-lg-6 col-md-10 my-2">
        <div className="customCard userHomeStatus">
          <div className="d-flex flex-column align-items-start">
            <h6 className="mt-2 title">NEW APPLICATION</h6>
            <div className="mt-4 w-100">
              <form action="">
                <div className="form-group d-flex flex-column align-items-start justify-content-between mb-2">
                  <label htmlFor="checkPryFour" className="mb-2">
                    Did the applicant just conclude Primary 4 ?{" "}
                    {formError.checkPryFour && (
                      <small className="px-1 py-2 text-danger">
                        {formError.checkPryFour}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <select
                      className="w-100 inputElement px-2 py-1"
                      name="checkPryFour"
                      id="checkPryFour"
                      defaultValue={formData.checkPryFour}
                      onChange={(event) => handleInputChange(event)}
                    >
                      <option value="">Select Answer</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                    <div className="input-group-postpend">
                      <span
                        className="input-group-text h-100 position-absolute"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          zIndex: 10,
                          transform: "translateX(-1.75rem)",
                        }}
                      >
                        <i>
                          <svg
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.2021 0.450301L7.75177 13.3546L0.301465 0.450301L15.2021 0.450301Z"
                              fill="#D9D9D9"
                            ></path>
                          </svg>
                        </i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group d-flex flex-column align-items-start justify-content-between mb-2">
                  <label htmlFor="applicantPresentClass" className="mb-2">
                    Applicant's Present Class{" "}
                    {formError.applicantPresentClass && (
                      <small className="px-1 py-2 text-danger">
                        {formError.applicantPresentClass}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <select
                      className="w-100 inputElement px-2 py-1"
                      name="applicantPresentClass"
                      id="applicantPresentClass"
                      defaultValue={formData.applicantPresentClass}
                      onChange={(event) => handleInputChange(event)}
                    >
                      <option value="">Select Class</option>
                      <option value="pry5">Pry 5</option>
                      <option value="pry6">Pry 6</option>
                      <option value="js1">JS 1</option>
                      <option value="js2">JS 2</option>
                      <option value="js3">JS 3</option>
                      <option value="ss1">SS 1</option>
                    </select>
                    <div className="input-group-postpend">
                      <span
                        className="input-group-text h-100 position-absolute"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          zIndex: 10,
                          transform: "translateX(-1.75rem)",
                        }}
                      >
                        <i>
                          <svg
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.2021 0.450301L7.75177 13.3546L0.301465 0.450301L15.2021 0.450301Z"
                              fill="#D9D9D9"
                            ></path>
                          </svg>
                        </i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group d-flex flex-column align-items-start justify-content-between mb-2">
                  <label htmlFor="applyingToClass" className="mb-2">
                    Applying To?{" "}
                    {formError.applyingToClass && (
                      <small className="px-1 py-2 text-danger">
                        {formError.applyingToClass}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <select
                      className="w-100 inputElement px-2 py-1"
                      name="applyingToClass"
                      id="applyingToClass"
                      defaultValue={formData.applyingToClass}
                      onChange={(event) => handleInputChange(event)}
                    >
                      <option value="">Select Class</option>
                      <option value="js1">JS 1</option>
                      <option value="js2">JS 2</option>
                      <option value="js3">JS 3</option>
                      <option value="ss1">SS 1</option>
                    </select>
                    <div className="input-group-postpend">
                      <span
                        className="input-group-text h-100 position-absolute"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          zIndex: 10,
                          transform: "translateX(-1.75rem)",
                        }}
                      >
                        <i>
                          <svg
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.2021 0.450301L7.75177 13.3546L0.301465 0.450301L15.2021 0.450301Z"
                              fill="#D9D9D9"
                            ></path>
                          </svg>
                        </i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3 d-flex flex-column align-items-start justify-content-between">
                  <label htmlFor="applicantReligion" className="mb-2">
                    Applicant's Religion{" "}
                    {formError.applicantReligion && (
                      <small className="px-1 py-2 text-danger">
                        {formError.applicantReligion}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <select
                      className="w-100 inputElement px-2 py-2"
                      name="applicantReligion"
                      id="applicantReligion"
                      defaultValue={formData.applicantReligion}
                      onChange={(event) => handleApplicantReligion(event)}
                    >
                      <option value="">Select Religion</option>
                      <option value="anglican">Anglican</option>
                      <option value="baptist">Baptist</option>
                      <option value="catholic">Catholic</option>
                      <option value="methodist">Methodist</option>
                      <option value="islam">Islam</option>
                      <option value="pentecostal">Pentecostal</option>
                      <option value="other">Other</option>
                    </select>
                    <div className="input-group-postpend">
                      <span
                        className="input-group-text h-100 position-absolute"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          zIndex: 10,
                          transform: "translateX(-1.75rem)",
                        }}
                      >
                        <i>
                          <svg
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.2021 0.450301L7.75177 13.3546L0.301465 0.450301L15.2021 0.450301Z"
                              fill="#D9D9D9"
                            ></path>
                          </svg>
                        </i>
                      </span>
                    </div>
                  </div>
                </div>
                {applicantOtherReligion && (
                  <div className="form-group mb-3 d-flex flex-column align-items-start justify-content-between">
                    <label htmlFor="applicantOtherReligion" className="mb-2">
                      Type in the Applicant's Religion{" "}
                      {formError.applicantOtherReligion && (
                        <small className="px-1 py-2 text-danger">
                          {formError.applicantOtherReligion}
                        </small>
                      )}
                    </label>
                    <div className="input-group">
                      <input
                        id="applicantOtherReligion"
                        type="text"
                        name="applicantOtherReligion"
                        className="py-1 w-100 px-3 inputElement bg-white"
                        value={
                          applicantOtherReligion
                            ? formData.applicantOtherReligion
                            : ""
                        }
                        onChange={(event) => {
                          handleInputChange(event);
                          console.log(formData.applicantOtherReligion);
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="form-group mb-2">
                  <label htmlFor="applicantPresentSchool" className="mb-2">
                    Present School{" "}
                    {formError.applicantPresentSchool && (
                      <small className="px-1 py-2 text-danger">
                        {formError.applicantPresentSchool}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <input
                      id="applicantPresentSchool"
                      type="text"
                      name="applicantPresentSchool"
                      className="py-1 w-100 px-3 inputElement bg-white"
                      value={formData.applicantPresentSchool}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </div>
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="applicantAwardsReceived" className="mb-2">
                    Award Received{" "}
                  </label>
                  <div className="input-group">
                    <input
                      id="applicantAwardsReceived"
                      type="text"
                      name="applicantAwardsReceived"
                      className="py-1 w-100 px-3 inputElement bg-white"
                      value={formData.applicantAwardsReceived}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="form-group mt-3">
                    <input
                      type="button"
                      name="btnPrev"
                      className="btnPrev"
                      value="Back"
                      onClick={goToPreviousPage}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <input
                      type="button"
                      name="btnNext"
                      className="btnNext"
                      value="Next"
                      disabled={disableNext}
                      onClick={(event) => handleNextClick(event)}
                    />
                  </div>
                </div>
              </form>
              {disableNext && (
                <p className="text-danger py-2">
                  Unfortunately, we do not take in students from Primary 4
                  below. Please contact admissions@whitesands.org.ng for more
                  information.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Apply3;
