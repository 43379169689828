import React from "react";

const Apply6 = ({
  goToNextPage,
  goToPreviousPage,
  formData,
  setFormData,
  formError,
  setFormError,
  fatherOtherReligion,
  setFatherOtherReligion,
}) => {
  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setFormError({
      ...formError,
      [event.target.name]: event.target.value ? "" : "This Field is required",
    });
  };

  const handleFatherReligion = (event) => {
    if (event.target.value !== "other") {
      setFormData((prevData) => ({
        ...prevData,
        fatherReligion: event.target.value,
        fatherOtherReligion: "",
      }));
      setFatherOtherReligion(false);
    } else if (event.target.value === "other") {
      setFormData((prevData) => ({
        ...prevData,
        fatherOtherReligion: "",
        fatherReligion: event.target.value,
      }));
      setFatherOtherReligion(true);
    }
  };
  const handleNextClick = (event) => {
    event.preventDefault();
    let isFormValid = true;

    if (!formData.fatherSurname) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        fatherSurname: "Father's Surname is required",
      }));
      isFormValid = false;
    }
    if (!formData.fatherFirstname) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        fatherFirstname: "Father's First Name is required",
      }));
      isFormValid = false;
    }
    if (!formData.fatherMiddlename) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        fatherMiddlename: "Father's Middle Name is required",
      }));
      isFormValid = false;
    }
    if (!formData.fatherMaritalStatus) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        fatherMaritalStatus: "Father's marital status is required",
      }));
      isFormValid = false;
    }
    if (!formData.fatherReligion) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        fatherReligion: "Father's religion is required",
      }));
      isFormValid = false;
    }
    if (fatherOtherReligion) {
      if (!formData.fatherOtherReligion) {
        setFormError((prevErrors) => ({
          ...prevErrors,
          fatherOtherReligion: "Father's religion is required",
        }));
        isFormValid = false;
      }
    }
    if (!formData.fatherPhoneNumber) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        fatherPhoneNumber: "Father's Phone number is required",
      }));
      isFormValid = false;
    }
    if (!formData.fatherEmail) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        fatherEmail: "Father's Email Address is required",
      }));
      isFormValid = false;
    }
    if (!formData.fatherWorkPlace) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        fatherWorkPlace: "Father's workplace is required",
      }));
      isFormValid = false;
    }

    if (!isFormValid) {
      console.log("Father Details Error");
      return;
    }
    console.log(formData);
    goToNextPage();
  };
  return (
    <div className="row mt-1">
      <div className="col-xl-4 col-lg-6 col-md-10 my-2">
        <div className="customCard userHomeStatus">
          <div className="d-flex flex-column align-items-start">
            <h6 className="mt-2 title">NEW APPLICATION</h6>
            <div className="mt-4 w-100">
              <form action="">
                <div className="form-group my-3">
                  <label htmlFor="fatherSurname" className="mb-2">
                    Father's Surname{" "}
                    {formError.fatherSurname && (
                      <small className="px-1 py-2 text-danger">
                        {formError.fatherSurname}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <input
                      id="fatherSurname"
                      type="text"
                      name="fatherSurname"
                      className="w-100 px-3 py-1 inputElement bg-white"
                      value={formData.fatherSurname}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </div>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="fatherFirstname" className="mb-2">
                    Father's First Name{" "}
                    {formError.fatherFirstname && (
                      <small className="px-1 py-2 text-danger">
                        {formError.fatherFirstname}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <input
                      id="fatherFirstname"
                      type="text"
                      name="fatherFirstname"
                      className="py-1 w-100 px-3 inputElement bg-white"
                      value={formData.fatherFirstname}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="fatherMiddlename" className="mb-2">
                    Father's Middle Name{" "}
                    {formError.fatherMiddlename && (
                      <small className="px-1 py-2 text-danger">
                        {formError.fatherMiddlename}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <input
                      id="fatherMiddlename"
                      type="text"
                      name="fatherMiddlename"
                      className="py-1 w-100 px-3 inputElement bg-white"
                      value={formData.fatherMiddlename}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </div>
                </div>
                <div className="form-group mb-3 d-flex flex-column align-items-start justify-content-between">
                  <label htmlFor="fatherMaritalStatus" className="mb-2">
                    Father's Marital Status{" "}
                    {formError.fatherMaritalStatus && (
                      <small className="px-1 py-2 text-danger">
                        {formError.fatherMaritalStatus}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <select
                      className="w-100 inputElement px-2 py-1"
                      name="fatherMaritalStatus"
                      id="fatherMaritalStatus"
                      defaultValue={formData.fatherMaritalStatus}
                      onChange={(event) => handleInputChange(event)}
                    >
                      <option value="">Select Marital Status</option>
                      <option value="single">Single</option>
                      <option value="married">Married</option>
                      <option value="divorced">Divorced</option>
                      <option value="separated">Separated</option>
                      <option value="widowed">Widowed</option>
                      <option value="deceased">Deceased</option>
                    </select>
                    <div className="input-group-postpend">
                      <span
                        className="input-group-text h-100 position-absolute"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          zIndex: 10,
                          transform: "translateX(-1.75rem)",
                        }}
                      >
                        <i>
                          <svg
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.2021 0.450301L7.75177 13.3546L0.301465 0.450301L15.2021 0.450301Z"
                              fill="#D9D9D9"
                            ></path>
                          </svg>
                        </i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3 d-flex flex-column align-items-start justify-content-between">
                  <label htmlFor="fatherReligion" className="mb-2">
                    Father's Religion{" "}
                    {formError.fatherReligion && (
                      <small className="px-1 py-2 text-danger">
                        {formError.fatherReligion}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <select
                      className="w-100 inputElement px-2 py-1"
                      name="fatherReligion"
                      id="fatherReligion"
                      defaultValue={formData.fatherReligion}
                      onChange={(event) => handleFatherReligion(event)}
                    >
                      <option value="">Select Religion</option>
                      <option value="anglican">Anglican</option>
                      <option value="baptist">Baptist</option>
                      <option value="catholic">Catholic</option>
                      <option value="methodist">Methodist</option>
                      <option value="islam">Islam</option>
                      <option value="pentecostal">Pentecostal</option>
                      <option value="other">Other</option>
                    </select>
                    <div className="input-group-postpend">
                      <span
                        className="input-group-text h-100 position-absolute"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          zIndex: 10,
                          transform: "translateX(-1.75rem)",
                        }}
                      >
                        <i>
                          <svg
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.2021 0.450301L7.75177 13.3546L0.301465 0.450301L15.2021 0.450301Z"
                              fill="#D9D9D9"
                            ></path>
                          </svg>
                        </i>
                      </span>
                    </div>
                  </div>
                </div>
                {fatherOtherReligion && (
                  <div className="form-group mb-3 d-flex flex-column align-items-start justify-content-between">
                    <label htmlFor="fatherOtherReligion" className="mb-2">
                      Type In Father's Religion{" "}
                      {formError.fatherOtherReligion && (
                        <small className="px-1 py-2 text-danger">
                          {formError.fatherOtherReligion}
                        </small>
                      )}
                    </label>
                    <div className="input-group">
                      <input
                        id="fatherOtherReligion"
                        type="text"
                        name="fatherOtherReligion"
                        className="py-1 w-100 px-3 inputElement bg-white"
                        value={formData.fatherOtherReligion}
                        onChange={(event) => handleInputChange(event)}
                      />
                    </div>
                  </div>
                )}
                <div className="form-group mb-2">
                  <label htmlFor="fatherPhoneNumber" className="mb-2">
                    Father's Phone number{" "}
                    {formError.fatherPhoneNumber && (
                      <small className="px-1 py-2 text-danger">
                        {formError.fatherPhoneNumber}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <input
                      id="fatherPhoneNumber"
                      type="number"
                      inputMode="numeric"
                      name="fatherPhoneNumber"
                      className="py-1 w-100 px-2 inputElement bg-white applicant-phone"
                      value={formData.fatherPhoneNumber}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </div>
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="fatherEmail" className="mb-2">
                    Father's Email address{" "}
                    {formError.fatherEmail && (
                      <small className="px-1 py-2 text-danger">
                        {formError.fatherEmail}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <input
                      id="fatherEmail"
                      type="text"
                      name="fatherEmail"
                      className="py-1 w-100 px-2 inputElement bg-white"
                      value={formData.fatherEmail}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </div>
                </div>
                <div className="form-group my-4">
                  <label htmlFor="fatherWorkPlace" className="mb-2">
                    Father's Work Place{" "}
                    {formError.fatherWorkPlace && (
                      <small className="px-1 py-2 text-danger">
                        {formError.fatherWorkPlace}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <input
                      id="fatherWorkPlace"
                      type="text"
                      name="fatherWorkPlace"
                      className="py-1 w-100 px-2 inputElement bg-white"
                      value={formData.fatherWorkPlace}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="form-group mt-3">
                    <input
                      type="button"
                      name="btnPrev"
                      className="btnPrev"
                      value="Back"
                      onClick={goToPreviousPage}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <input
                      type="button"
                      name="btnNext"
                      className="btnNext"
                      value="Next"
                      onClick={(event) => handleNextClick(event)}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Apply6;
