import { useMutation, useQueryClient } from "react-query";

const useGetData = (url, onSuccessCallback, headers, onErrorCallback) => {
  const queryClient = useQueryClient();

  const getDataMutation = useMutation(
    () =>
      fetch(url, {
        method: "GET",
        headers: {
          ...headers,
        },
      }),
    {
      // Optional: You can handle query invalidation, onSuccess, onError, etc.
      // For example, you can invalidate a query after the mutation is successful.
      onSuccess: async (response) => {
        const data = await response.json();
        if (onSuccessCallback) {
          onSuccessCallback(data);
        }
        queryClient.invalidateQueries("yourQueryKey");
      },
      onError: (error) => {
        if (onErrorCallback) {
          onErrorCallback(error);
        }
        queryClient.invalidateQueries("yourQueryKey");
      },
    }
  );

  return getDataMutation;
};

export default useGetData;
