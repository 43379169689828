import React from "react";
import { AiOutlineSearch } from "react-icons/ai";

const AdminSearchComponent = () => {
  return (
    <div className="row mt-4">
      <div className="col-sm-6">
        <div className="d-flex align-items-center" style={{ gap: "10px" }}>
          <div className="position-relative">
            <span className="position-absolute userSearchIcon">
              <AiOutlineSearch />
            </span>
            <input
              type="text"
              name=""
              id=""
              placeholder="Search"
              className="userSearchInput h-100"
            />
          </div>
          <button className="userSearchButton">Submit</button>
        </div>
      </div>
    </div>
  );
};

export default AdminSearchComponent;
