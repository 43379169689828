import React, { useState } from "react";
import { Form } from "react-bootstrap";

const UserInterviewForm = ({
  dates,
  interviewDateSelect,
  setInterviewDateSelect,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [error, setError] = useState(null);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    setError(null); // Reset the error message when a date is selected
  };
  const handleSubmit = (event) => {
    if (!selectedDate) {
      setError("Please select an interview date."); // Display error if no date is selected
      return;
    }

    // Replace 'submitInterviewDate' with your actual API endpoint
    setInterviewDateSelect(true);
  };
  return (
    <div className="row mt-4">
      <div className="col-xl-4 col-lg-6 col-md-10 my-2">
        <div className="customCard userHomeStatus">
          <div className="d-flex flex-column align-items-start">
            <h6 className="mt-2 title">Interview date</h6>
            <div className="mt-4 w-100">
              <p>Choose Interview Date</p>
              <Form>
                {dates.map((date) => (
                  <Form.Check
                    key={date.id}
                    type="radio"
                    name="interviewDate"
                    id={`interviewDate_${date.id}`} // Add a unique id to the input
                    value={date.id} // Use the ID of the date as the value attribute
                    label={date.date}
                    checked={selectedDate === date.id} // Use the controlled component approach
                    onChange={handleDateChange}
                    className="interview-date-item"
                  />
                ))}

                <div className="form-group mt-3">
                  <input
                    type="button"
                    name="btnNext"
                    className="btnNext"
                    value="Submit"
                    onClick={handleSubmit}
                  />
                </div>
              </Form>
              {error && <p className="text-danger">{error}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInterviewForm;
