import React, { useContext, useState } from "react";
import AdminDataContext from "../context/AdminDataContext";
import AdminApplicantsTable from "./AdminApplicantsTable";
import AdminExaminationTable from "./AdminExaminationTable";
import AdminInterviewComponent from "./AdminInterviewComponent";

const AdminMainContent = ({
  userInfoModal,
  setUserInfoModal,
  setSelectedApplicant,
  sidebarContentSelect,
}) => {
  const {
    selectedApplicants,
    setSelectedApplicants,
    setCurrentApplicantsPage,
    setSelectedUser,
    setShowCalendarModal,
  } = useContext(AdminDataContext);

  const handleExamEvent = (selectedUser) => {
    setSelectedUser(selectedUser);
    setShowCalendarModal("send_exam");
  };

  const handleBulkExamEvent = (action) => {
    if (action === "send_bulk_exam") {
      setShowCalendarModal("send_bulk_exam");
    }
  };

  const handleRowClick = (data) => {
    setSelectedApplicant(data);
    setUserInfoModal(!userInfoModal);
  };

  const handleCellClick = (event) => {
    event.stopPropagation();
  };

  // const paginatedData = filteredApplicantsData.slice(startIndex, endIndex);

  const [selectedOption, setSelectedOption] = useState(null);

  // const handleBulkAction = async (action) => {
  //   // Define the data to send as an object
  //   const dataToSend = {
  //     userIds: selectedApplicants,
  //     action: action,
  //   };

  //   // Define the URL
  //   const url = "http://localhost:8080/whitesandsback/apis/admin/bulkaction";

  //   // Set up the request config with the correct headers
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json", // Specify JSON content type
  //     },
  //   };

  //   try {
  //     // Send the POST request with axios
  //     const response = await axios.post(url, dataToSend, config);

  //     // Handle the response here
  //     console.log(response.data);

  //     // You can add further logic to handle the response based on your application's needs
  //   } catch (err) {
  //     // Handle any errors here
  //     console.error(err);
  //   }
  // };

  // const handleAcceptOrRejectEvent = async (userId, action) => {
  //   // Define the API endpoint URL
  //   const apiUrl = `http://localhost:8080/whitesandsback/apis/admin/acceptorreject`;

  //   // Create an object containing the data to send to the backend
  //   const requestData = {
  //     userId: userId,
  //     action: action,
  //   };

  //   // Send the API request to perform the action using Axios
  //   await axios
  //     .post(apiUrl, requestData)
  //     .then((response) => {
  //       console.log(response);
  //       const data = response.data;
  //       if (data.success) {
  //         // Application action (accept or reject) succeeded, you can show a notification
  //         setShowNotificationModal(true);
  //       } else {
  //         // Handle errors, e.g., by displaying an error message
  //         console.error(`Failed to ${action} application:`, data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       // Handle network errors
  //       console.error("Network error:", error);
  //     });
  // };

  // const getRowColor = (status, extras) => {
  //   if (status === "accepted") {
  //     return "#C8E6C9"; // Green color for accepted status
  //   } else if (status === "rejected") {
  //     return "#FFCDD2"; // Red color for rejected status
  //   } else {
  //     return "#FFFFFF"; // Default color for other statuses
  //   }
  // };

  const toggleCheckBox = (e, applicant) => {
    if (e.target.checked) {
      // Checkbox is checked, add the applicant to the selectedApplicants array
      setSelectedApplicants((prevSelected) => [
        ...prevSelected,
        {
          userId: applicant.userId,
          title: applicant.applicantTitle,
          surName: applicant.applicantSurname,
          firstName: applicant.applicantFirstname,
          dateOfBirth: applicant.applicantDob,
          emailAddress: applicant.applicantEmail,
          uac: applicant.applicationId,
          applyingToClass: applicant.applyingToClass,
          applicantPresentClass: applicant.applicantPresentClass,
        },
      ]);
      console.log(selectedApplicants);
    } else {
      // Checkbox is unchecked, remove the applicant from the selectedApplicants array
      setSelectedApplicants((prevSelected) =>
        prevSelected.filter(
          (selected) => selected.uac !== applicant.applicationId
        )
      );
    }
  };
  const handleInterviewEvent = () => {
    setShowCalendarModal("interview");
  };
  const handlePageChange = (newPage) => {
    setCurrentApplicantsPage(newPage);
  };
  return (
    <React.Fragment>
      {sidebarContentSelect === "dashboard" && (
        <AdminApplicantsTable
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          handleExamEvent={handleExamEvent}
          handleBulkExamEvent={handleBulkExamEvent}
          handleRowClick={handleRowClick}
          toggleCheckBox={toggleCheckBox}
          handleCellClick={handleCellClick}
          handlePageChange={handlePageChange}
          handleInterviewEvent={handleInterviewEvent}
        />
      )}
      {sidebarContentSelect === "examination" && (
        <AdminExaminationTable
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          handleExamEvent={handleExamEvent}
          handleBulkExamEvent={handleBulkExamEvent}
          handleRowClick={handleRowClick}
          toggleCheckBox={toggleCheckBox}
          handleCellClick={handleCellClick}
          handlePageChange={handlePageChange}
          handleInterviewEvent={handleInterviewEvent}
        />
      )}

      {sidebarContentSelect === "interview" && (
        <AdminInterviewComponent
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          handleExamEvent={handleExamEvent}
          handleBulkExamEvent={handleBulkExamEvent}
          handleRowClick={handleRowClick}
          toggleCheckBox={toggleCheckBox}
          handleCellClick={handleCellClick}
          handlePageChange={handlePageChange}
          handleInterviewEvent={handleInterviewEvent}
        />
      )}
    </React.Fragment>
  );
};

export default AdminMainContent;
