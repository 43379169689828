import { useContext } from "react";
import FormContext from "../../context/FormContext";
import Apply1 from "./Apply1";
import Apply2 from "./Apply2";
import Apply3 from "./Apply3";
import Apply4 from "./Apply4";
import Apply5 from "./Apply5";
import Apply6 from "./Apply6";
import Apply7 from "./Apply7";
import Apply8 from "./Apply8";
import Apply9 from "./Apply9";

const ApplyForm = () => {
  const {
    formData,
    setFormData,
    formError,
    setFormError,
    currentForm,
    goToNextPage,
    goToPreviousPage,
    showSubmitModal,
    setShowSubmitModal,
    handleShow,
    handleClose,
    doHaveSiblings,
    setDoHaveSiblings,
    doHaveSisterInLagoon,
    setdoHaveSisterInLagoon,
    disableNext,
    setDisableNext,
    otherArea,
    setOtherArea,
    otherNationality,
    setOtherNationality,
    applicantOtherReligion,
    setApplicantOtherReligion,
    fatherOtherReligion,
    setFatherOtherReligion,
    motherOtherReligion,
    setMotherOtherReligion,
  } = useContext(FormContext);

  // Render the current form page based on the currentPage state
  const renderFormPage = () => {
    switch (currentForm) {
      case 1:
        return (
          <Apply1
            goToNextPage={goToNextPage}
            formData={formData}
            setFormData={setFormData}
            formError={formError}
            setFormError={setFormError}
            disableNext={disableNext}
            setDisableNext={setDisableNext}
          />
        );
      case 2:
        return (
          <Apply2
            goToNextPage={goToNextPage}
            goToPreviousPage={goToPreviousPage}
            formData={formData}
            setFormData={setFormData}
            formError={formError}
            setFormError={setFormError}
            disableNext={disableNext}
            setDisableNext={setDisableNext}
          />
        );
      case 3:
        return (
          <Apply3
            goToNextPage={goToNextPage}
            goToPreviousPage={goToPreviousPage}
            formData={formData}
            setFormData={setFormData}
            formError={formError}
            setFormError={setFormError}
            disableNext={disableNext}
            setDisableNext={setDisableNext}
            applicantOtherReligion={applicantOtherReligion}
            setApplicantOtherReligion={setApplicantOtherReligion}
          />
        );
      case 4:
        return (
          <Apply4
            goToNextPage={goToNextPage}
            goToPreviousPage={goToPreviousPage}
            formData={formData}
            setFormData={setFormData}
            formError={formError}
            setFormError={setFormError}
            otherArea={otherArea}
            setOtherArea={setOtherArea}
            otherNationality={otherNationality}
            setOtherNationality={setOtherNationality}
          />
        );
      case 5:
        return (
          <Apply5
            goToNextPage={goToNextPage}
            goToPreviousPage={goToPreviousPage}
            formData={formData}
            setFormData={setFormData}
            formError={formError}
            setFormError={setFormError}
            doHaveSiblings={doHaveSiblings}
            setDoHaveSiblings={setDoHaveSiblings}
          />
        );
      case 6:
        return (
          <Apply6
            goToNextPage={goToNextPage}
            goToPreviousPage={goToPreviousPage}
            formData={formData}
            setFormData={setFormData}
            formError={formError}
            setFormError={setFormError}
            fatherOtherReligion={fatherOtherReligion}
            setFatherOtherReligion={setFatherOtherReligion}
          />
        );
      case 7:
        return (
          <Apply7
            goToNextPage={goToNextPage}
            goToPreviousPage={goToPreviousPage}
            formData={formData}
            setFormData={setFormData}
            formError={formError}
            setFormError={setFormError}
            motherOtherReligion={motherOtherReligion}
            setMotherOtherReligion={setMotherOtherReligion}
          />
        );
      case 8:
        return (
          <Apply8
            goToNextPage={goToNextPage}
            goToPreviousPage={goToPreviousPage}
            formData={formData}
            setFormData={setFormData}
            formError={formError}
            setFormError={setFormError}
            doHaveSisterInLagoon={doHaveSisterInLagoon}
            setdoHaveSisterInLagoon={setdoHaveSisterInLagoon}
          />
        );
      case 9:
        return (
          <Apply9
            goToNextPage={goToNextPage}
            goToPreviousPage={goToPreviousPage}
            formData={formData}
            setFormData={setFormData}
            formError={formError}
            setFormError={setFormError}
            showSubmitModal={showSubmitModal}
            setShowSubmitModal={setShowSubmitModal}
            handleShow={handleShow}
            handleClose={handleClose}
          />
        );
      default:
        return null;
    }
  };
  return <>{renderFormPage()}</>;
};

export default ApplyForm;
