import React from "react";
import ApplyForm from "./Forms/ApplyForm";
import DashboardInfo from "./DashboardInfo";
import DocUpload from "./DocUpload";
import UserInterviewForm from "./Forms/UserInterviewForm";
import NotificationModal from "./Modals/NotificationModal";
import { FormProvider } from "../context/FormContext";

const UserMainContent = ({
  handleClick,
  sidebarContentSelect,
  applicantData,
  interviewDateSelect,
  setInterviewDateSelect,
  documentSubmitted,
  setDocumentSubmitted,
}) => {
  const interviewDates = [
    { id: 1, date: "2023-07-25" },
    { id: 2, date: "2023-07-28" },
    { id: 3, date: "2023-07-31" },
  ];
  return (
    <main className="mainContent">
      <div className="container-fluid">
        {sidebarContentSelect === "dashboard" && (
          <DashboardInfo
            applicantData={applicantData}
            handleClick={handleClick}
          />
        )}
        {sidebarContentSelect === "apply" && (
          <React.Fragment>
            <FormProvider>
              <ApplyForm />
            </FormProvider>
          </React.Fragment>
        )}
        {sidebarContentSelect === "docupload" && (
          <DocUpload setDocumentSubmitted={setDocumentSubmitted} />
        )}
        {sidebarContentSelect === "interview" && (
          <React.Fragment>
            <UserInterviewForm
              dates={interviewDates}
              interviewDateSelect={interviewDateSelect}
              setInterviewDateSelect={setInterviewDateSelect}
            />
          </React.Fragment>
        )}
      </div>
      {interviewDateSelect && (
        <NotificationModal
          show={interviewDateSelect}
          message={"Interview Date Selected"}
        />
      )}
    </main>
  );
};

export default UserMainContent;
