import { useState } from "react";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";

const CustomSelect = ({ options, selectedOption, setSelectedOption }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="customSelectDropdown mb-2">
      <div className="selectHeader" onClick={toggleDropdown}>
        {selectedOption ? selectedOption.label : "Bulk action"}
        {isDropdownOpen ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />}
      </div>
      {isDropdownOpen && (
        <div className="optionsContainer">
          {options.map((option) => (
            <div
              key={option.value}
              className="option"
              onClick={() => handleOptionChange(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
