import React from "react";

const Apply5 = ({
  goToNextPage,
  goToPreviousPage,
  formData,
  setFormData,
  formError,
  setFormError,
  doHaveSiblings,
  setDoHaveSiblings,
}) => {
  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setFormError({
      ...formError,
      [event.target.name]: event.target.value ? "" : "This Field is required",
    });
  };
  const handleHaveSiblings = (event) => {
    handleInputChange(event);
    if (event.target.value === "yes") {
      setDoHaveSiblings(true);
    } else if (event.target.value === "no") {
      setDoHaveSiblings(false);
    }
  };
  const handleNextClick = (event) => {
    event.preventDefault();
    let isFormValid = true;

    if (!formData.haveSiblings) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        haveSiblings: "Please answer this question",
      }));
      isFormValid = false;
    }
    if (doHaveSiblings) {
      if (!formData.oldestSibling) {
        setFormError((prevErrors) => ({
          ...prevErrors,
          oldestSibling: "Answer is required",
        }));
        isFormValid = false;
      }
      if (!formData.oldestSiblingClass) {
        setFormError((prevErrors) => ({
          ...prevErrors,
          oldestSiblingClass: "Answer is required",
        }));
        isFormValid = false;
      }
      if (!formData.howManyOtherSibling) {
        setFormError((prevErrors) => ({
          ...prevErrors,
          howManyOtherSibling: "Answer is required",
        }));
        isFormValid = false;
      }
    }

    if (!isFormValid) {
      console.log("Form Error");
      return;
    }
    console.log(formData);
    goToNextPage();
  };
  return (
    <div className="row mt-1">
      <div className="col-xl-4 col-lg-6 col-md-10 my-2">
        <div className="customCard userHomeStatus">
          <div className="d-flex flex-column align-items-start">
            <h6 className="mt-2 title">NEW APPLICATION</h6>
            <div className="mt-4 w-100">
              <form action="">
                <div className="form-group mb-3">
                  <label htmlFor="haveSiblings" className="mb-2">
                    Does the applicant have a sibling in Whitesands?{" "}
                    {formError.haveSiblings && (
                      <small className="px-1 py-2 text-danger">
                        {formError.haveSiblings}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <select
                      className="w-100 inputElement px-2 py-2"
                      name="haveSiblings"
                      id="haveSiblings"
                      defaultValue={formData.haveSiblings}
                      onChange={(event) => handleHaveSiblings(event)}
                    >
                      <option value="">Select Answer</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                    <div className="input-group-postpend">
                      <span
                        className="input-group-text h-100 position-absolute"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          zIndex: 10,
                          transform: "translateX(-1.75rem)",
                        }}
                      >
                        <i>
                          <svg
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.2021 0.450301L7.75177 13.3546L0.301465 0.450301L15.2021 0.450301Z"
                              fill="#D9D9D9"
                            ></path>
                          </svg>
                        </i>
                      </span>
                    </div>
                  </div>
                </div>
                {doHaveSiblings && (
                  <React.Fragment>
                    <div className="form-group mb-3">
                      <label htmlFor="oldestSibling" className="mb-2">
                        Name of oldest sibling in Whitesands{" "}
                        {formError.oldestSibling && (
                          <small className="px-1 py-2 text-danger">
                            {formError.oldestSibling}
                          </small>
                        )}
                      </label>
                      <div className="input-group">
                        <input
                          id="oldestSibling"
                          type="text"
                          name="oldestSibling"
                          className="py-1 w-100 px-3 inputElement bg-white"
                          value={formData.oldestSibling}
                          onChange={(event) => handleInputChange(event)}
                        />
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="oldestSiblingClass" className="mb-2">
                        Class of oldest sibling in Whitesands{" "}
                        {formError.oldestSiblingClass && (
                          <small className="px-1 py-2 text-danger">
                            {formError.oldestSiblingClass}
                          </small>
                        )}
                      </label>
                      <div className="input-group">
                        <select
                          className="w-100 inputElement px-2 py-2"
                          name="oldestSiblingClass"
                          id="oldestSiblingClass"
                          defaultValue={formData.oldestSiblingClass}
                          onChange={(event) => handleHaveSiblings(event)}
                        >
                          <option value="">Select Class</option>
                          <option value="js1">JS1</option>
                          <option value="js2">JS2</option>
                          <option value="js3">JS3</option>
                          <option value="ss1">SS1</option>
                          <option value="ss2">SS2</option>
                          <option value="ss3">SS3</option>
                        </select>
                        <div className="input-group-postpend">
                          <span
                            className="input-group-text h-100 position-absolute"
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              zIndex: 10,
                              transform: "translateX(-1.75rem)",
                            }}
                          >
                            <i>
                              <svg
                                width="16"
                                height="14"
                                viewBox="0 0 16 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M15.2021 0.450301L7.75177 13.3546L0.301465 0.450301L15.2021 0.450301Z"
                                  fill="#D9D9D9"
                                ></path>
                              </svg>
                            </i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="howManyOtherSibling" className="mb-2">
                        How many other siblings in Whitesands{" "}
                        {formError.howManyOtherSibling && (
                          <small className="px-1 py-2 text-danger">
                            {formError.howManyOtherSibling}
                          </small>
                        )}
                      </label>
                      <div className="input-group">
                        <select
                          className="w-100 inputElement px-2 py-2"
                          name="howManyOtherSibling"
                          id="howManyOtherSibling"
                          defaultValue={formData.howManyOtherSibling}
                          onChange={(event) => handleHaveSiblings(event)}
                        >
                          <option value="">Select Answer</option>
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5 and above</option>
                        </select>
                        <div className="input-group-postpend">
                          <span
                            className="input-group-text h-100 position-absolute"
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              zIndex: 10,
                              transform: "translateX(-1.75rem)",
                            }}
                          >
                            <i>
                              <svg
                                width="16"
                                height="14"
                                viewBox="0 0 16 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M15.2021 0.450301L7.75177 13.3546L0.301465 0.450301L15.2021 0.450301Z"
                                  fill="#D9D9D9"
                                ></path>
                              </svg>
                            </i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                <div className="d-flex justify-content-between">
                  <div className="form-group mt-3">
                    <input
                      type="button"
                      name="btnPrev"
                      className="btnPrev"
                      value="Back"
                      onClick={goToPreviousPage}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <input
                      type="button"
                      name="btnNext"
                      className="btnNext"
                      value="Next"
                      onClick={(event) => handleNextClick(event)}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Apply5;
