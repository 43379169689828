import React from "react";
import { Link } from "react-router-dom";

const NavTab = ({ contentSelect, handleClick }) => {
  return (
    <div className="navTab">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <ul className="d-flex px-0 mb-0">
              <li
                className={`py-3 px-4 w-50 nav-link ${
                  contentSelect === "dashboard" ? "active" : ""
                }`}
              >
                <Link
                  className="text-decoration-none mb-4 dashboard"
                  onClick={() => handleClick("dashboard")}
                >
                  <div className="d-flex align-items-center">
                    <span className="d-flex align-items-center justify-content-center optionIcon mx-2">
                      <svg
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20ZM16 18V2H2V18H16ZM5 5H13V7H5V5ZM5 9H13V11H5V9ZM5 13H10V15H5V13Z"
                          fill="white"
                        ></path>
                      </svg>
                    </span>
                    <p className="text-decoration-none text-black mb-0">
                      Dashboard
                    </p>
                  </div>
                </Link>
              </li>
              <li
                className={`py-3 px-4 w-50 nav-link ${
                  contentSelect === "apply" ? "active" : ""
                }`}
              >
                <Link
                  className="text-decoration-none mb-3"
                  onClick={() => handleClick("apply")}
                >
                  <div className="d-flex align-items-center">
                    <span className="d-flex align-items-center justify-content-center mx-2 optionIcon">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 22H18V20C18 18.3431 16.6569 17 15 17H9C7.34315 17 6 18.3431 6 20V22H4V20C4 17.2386 6.23858 15 9 15H15C17.7614 15 20 17.2386 20 20V22ZM12 13C8.68629 13 6 10.3137 6 7C6 3.68629 8.68629 1 12 1C15.3137 1 18 3.68629 18 7C18 10.3137 15.3137 13 12 13ZM12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                          fill="#E9EFFD"
                        ></path>
                      </svg>
                    </span>
                    <p className="text-decoration-none text-black mb-0">
                      Apply
                    </p>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavTab;
