import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserDataContext from "./UserDataContext";

const FormContext = createContext();

export function FormProvider({ children }) {
  const { applicantData, showSubmitModal, setShowSubmitModal } =
    useContext(UserDataContext);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    applicantTitle: "",
    applicantGender: "",
    whoFillForm: "",
    applicantEmail: applicantData.emailAddress,
    applicantSurname: "",
    applicantFirstname: "",
    applicantMiddlename: "",
    applicantDob: "",
    applicantPhonenumber: null,
    checkPryFour: "",
    applicantPresentClass: "",
    applyingToClass: "",
    applicantReligion: "",
    applicantOtherReligion: "",
    applicantPresentSchool: "",
    applicantAwardsReceived: "",
    applicantHomeAddress: "",
    applicantAreaofLagos: "",
    otherAreaofLagos: "",
    applicantNationality: "",
    otherNationality: "",
    applicantPlaceofBirth: "",
    haveSiblings: "",
    oldestSibling: "",
    oldestSiblingClass: "",
    howManyOtherSibling: "",
    fatherSurname: "",
    fatherFirstname: "",
    fatherMiddlename: "",
    fatherMaritalStatus: "",
    fatherReligion: "",
    fatherOtherReligion: "",
    fatherPhoneNumber: "",
    fatherEmail: "",
    fatherWorkPlace: "",
    motherSurname: "",
    motherFirstname: "",
    motherMiddlename: "",
    motherMaritalStatus: "",
    motherReligion: "",
    motherOtherReligion: "",
    motherPhoneNumber: "",
    motherEmail: "",
    motherWorkPlace: "",
    haveSisterInLagoon: "",
    eldestSisterName: "",
    sisterClass: "",
    referralName: "",
    referralEmail: "",
    referralPhoneNumber: "",
  });
  const [formError, setFormError] = useState({
    applicantTitle: "",
    applicantGender: "",
    whoFillForm: "",
    applicantEmail: "",
    applicantSurname: "",
    applicantFirstname: "",
    applicantMiddlename: "",
    applicantDob: "",
    applicantPhonenumber: null,
    checkPryFour: "",
    applicantPresentClass: "",
    applyingToClass: "",
    applicantAwardsReceived: "",
    applicantHomeAddress: "",
    applicantAreaofLagos: "",
    otherAreaofLagos: "",
    applicantNationality: "",
    otherNationality: "",
    applicantPlaceofBirth: "",
    applicantReligion: "",
    applicantOtherReligion: "",
    haveSiblings: "",
    oldestSibling: "",
    oldestSiblingClass: "",
    howManyOtherSibling: "",
    fatherSurname: "",
    fatherFirstname: "",
    fatherMiddlename: "",
    fatherMaritalStatus: "",
    fatherReligion: "",
    fatherOtherReligion: "",
    fatherPhoneNumber: "",
    fatherEmail: "",
    fatherWorkPlace: "",
    motherSurname: "",
    motherFirstname: "",
    motherMiddlename: "",
    motherMaritalStatus: "",
    motherReligion: "",
    motherOtherReligion: "",
    motherPhoneNumber: "",
    motherEmail: "",
    motherWorkPlace: "",
    haveSisterInLagoon: "",
    eldestSisterName: "",
    sisterClass: "",
    referralName: "",
    referralEmail: "",
    referralPhoneNumber: "",
  });
  const [currentForm, setCurrentForm] = useState(1);
  const [disableNext, setDisableNext] = useState(false);

  const [otherArea, setOtherArea] = useState(false);
  const [otherNationality, setOtherNationality] = useState(false);
  const [applicantOtherReligion, setApplicantOtherReligion] = useState(false);
  const [fatherOtherReligion, setFatherOtherReligion] = useState(false);
  const [motherOtherReligion, setMotherOtherReligion] = useState(false);

  const [doHaveSiblings, setDoHaveSiblings] = useState(false);
  const [doHaveSisterInLagoon, setdoHaveSisterInLagoon] = useState(false);

  const handleClose = () => {
    setFormData({
      applicantTitle: "",
      applicantGender: "",
      whoFillForm: "",
      applicantEmail: applicantData.emailAddress,
      applicantSurname: "",
      applicantFirstname: "",
      applicantMiddlename: "",
      applicantDob: "",
      applicantPhonenumber: null,
      checkPryFour: "",
      applicantPresentClass: "",
      applyingToClass: "",
      applicantReligion: "",
      applicantOtherReligion: "",
      applicantPresentSchool: "",
      applicantAwardsReceived: "",
      applicantHomeAddress: "",
      applicantAreaofLagos: "",
      otherAreaofLagos: "",
      applicantNationality: "",
      otherNationality: "",
      applicantPlaceofBirth: "",
      haveSiblings: "",
      oldestSibling: "",
      oldestSiblingClass: "",
      howManyOtherSibling: "",
      fatherSurname: "",
      fatherFirstname: "",
      fatherMiddlename: "",
      fatherMaritalStatus: "",
      fatherReligion: "",
      fatherOtherReligion: "",
      fatherPhoneNumber: "",
      fatherEmail: "",
      fatherWorkPlace: "",
      motherSurname: "",
      motherFirstname: "",
      motherMiddlename: "",
      motherMaritalStatus: "",
      motherReligion: "",
      motherOtherReligion: "",
      motherPhoneNumber: "",
      motherEmail: "",
      motherWorkPlace: "",
      haveSisterInLagoon: "",
      eldestSisterName: "",
      sisterClass: "",
      referralName: "",
      referralEmail: "",
      referralPhoneNumber: "",
    });
    setFormError({
      applicantTitle: "",
      applicantGender: "",
      whoFillForm: "",
      applicantEmail: applicantData.emailAddress,
      applicantSurname: "",
      applicantFirstname: "",
      applicantMiddlename: "",
      applicantDob: "",
      applicantPhonenumber: null,
      checkPryFour: "",
      applicantPresentClass: "",
      applyingToClass: "",
      applicantReligion: "",
      applicantOtherReligion: "",
      applicantPresentSchool: "",
      applicantAwardsReceived: "",
      applicantHomeAddress: "",
      applicantAreaofLagos: "",
      otherAreaofLagos: "",
      applicantNationality: "",
      otherNationality: "",
      applicantPlaceofBirth: "",
      haveSiblings: "",
      oldestSibling: "",
      oldestSiblingClass: "",
      howManyOtherSibling: "",
      fatherSurname: "",
      fatherFirstname: "",
      fatherMiddlename: "",
      fatherMaritalStatus: "",
      fatherReligion: "",
      fatherOtherReligion: "",
      fatherPhoneNumber: "",
      fatherEmail: "",
      fatherWorkPlace: "",
      motherSurname: "",
      motherFirstname: "",
      motherMiddlename: "",
      motherMaritalStatus: "",
      motherReligion: "",
      motherOtherReligion: "",
      motherPhoneNumber: "",
      motherEmail: "",
      motherWorkPlace: "",
      haveSisterInLagoon: "",
      eldestSisterName: "",
      sisterClass: "",
      referralName: "",
      referralEmail: "",
      referralPhoneNumber: "",
    });
    setShowSubmitModal(false);
    setTimeout(() => {
      navigate("/userhome");
      window.location.reload(true);
    }, 2000);
  };
  const handleShow = () => setShowSubmitModal(true);

  const goToNextPage = () => {
    setCurrentForm(currentForm + 1);
  };

  const goToPreviousPage = () => {
    setCurrentForm(currentForm - 1);
  };
  return (
    <FormContext.Provider
      value={{
        formData,
        setFormData,
        formError,
        setFormError,
        currentForm,
        goToNextPage,
        goToPreviousPage,
        showSubmitModal,
        setShowSubmitModal,
        handleClose,
        handleShow,
        doHaveSiblings,
        setDoHaveSiblings,
        doHaveSisterInLagoon,
        setdoHaveSisterInLagoon,
        disableNext,
        setDisableNext,
        otherArea,
        setOtherArea,
        otherNationality,
        setOtherNationality,
        applicantOtherReligion,
        setApplicantOtherReligion,
        fatherOtherReligion,
        setFatherOtherReligion,
        motherOtherReligion,
        setMotherOtherReligion,
      }}
    >
      {children}
    </FormContext.Provider>
  );
}

export default FormContext;
