import React, { useContext } from "react";
import { Dropdown } from "react-bootstrap";
import AdminSearchComponent from "./AdminSearchComponent";
import AdminDataContext from "../context/AdminDataContext";
import CustomSelect from "./CustomSelect";

const AdminInterviewComponent = ({
  selectedOption,
  setSelectedOption,
  handleExamEvent,
  handleBulkExamEvent,
  handleRowClick,
  toggleCheckBox,
  handleCellClick,
  handlePageChange,
  handleInterviewEvent,
}) => {
  const {
    applicantsData,
    handleBulkAction,
    handlePassOrFailExam,
    filteredApplicantsData,
    setFilteredApplicantsData,
    currentApplicantsPage,
    setCurrentApplicantsPage,
    applicantsPageSize,
    bulkOptions,
    setBulkOption,
    allowBulkActions,
    setAllowBulkAction,
  } = useContext(AdminDataContext);
  return (
    <main className="mainContent">
      <div className="container-fluid">
        <AdminSearchComponent />
        <div>
          <div className="row mt-1">
            <div className="col-12 my-2">
              <div className="customCard" style={{ zIndex: "-1" }}>
                <div>
                  <h6 className="mt-2 title">Exam Candidates</h6>
                  <div className="mt-4 w-100">
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{ gap: "10px" }}
                    >
                      {allowBulkActions !== "" && (
                        <div className="d-flex">
                          <CustomSelect
                            options={bulkOptions}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                          />
                          <div className="mb-2 px-2">
                            <button
                              className=""
                              style={{
                                padding: "0.7rem 2.5rem",
                                backgroundColor: "#4FADEA",
                                border: "none",
                                borderRadius: "25px",
                                color: "#fff",
                              }}
                              onClick={() => {
                                selectedOption.value === "send_exam"
                                  ? handleBulkExamEvent("send_bulk_exam")
                                  : handleBulkAction(selectedOption.value);
                              }}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      )}

                      <div>
                        <select
                          defaultValue="all_exam_candidate"
                          onChange={(event) => ""}
                        >
                          <option value="all_exam_candidate">
                            All Candidates
                          </option>
                          <option value="pending_exam">Pending Exam</option>
                          <option value="passed_exam">Passed Candidates</option>
                          <option value="failed_exam">Failed Candidates</option>
                        </select>
                      </div>
                    </div>
                    <div className="applicants-table"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AdminInterviewComponent;
