import React, { useState, useContext, useEffect } from "react";
import TopNav from "../components/TopNav";
import SideBar from "../components/UserSideBar";
import UserMainContent from "../components/UserMainContent";
import NavTab from "../components/NavTab";
import UserDataContext from "../context/UserDataContext";
import NotificationModal from "../components/Modals/NotificationModal";

const UserHome = () => {
  const [interviewDateSelect, setInterviewDateSelect] = useState(false);

  const {
    applicantData,
    fetchApplicantData,
    checkTokenExpiration,
    documentSubmitted,
    setDocumentSubmitted,
    sidebarContentSelect,
    setsidebarContentSelect,
  } = useContext(UserDataContext);

  useEffect(() => {
    checkTokenExpiration();
    fetchApplicantData(); // Call the fetch function when UserHome mounts
  }, []);

  setInterval(checkTokenExpiration, 60000); // Check every minute

  const handleClick = (param) => {
    setsidebarContentSelect(param);
  };
  return (
    <React.Fragment>
      <div className="wrapper">
        <TopNav applicantData={applicantData} />
        <div className="main-container">
          <SideBar handleClick={handleClick} />
          <NavTab
            sidebarContentSelect={sidebarContentSelect}
            handleClick={handleClick}
          />
          <UserMainContent
            handleClick={handleClick}
            sidebarContentSelect={sidebarContentSelect}
            applicantData={applicantData}
            interviewDateSelect={interviewDateSelect}
            setInterviewDateSelect={setInterviewDateSelect}
            documentSubmitted={documentSubmitted}
            setDocumentSubmitted={setDocumentSubmitted}
          />
        </div>
      </div>
      {documentSubmitted && (
        <NotificationModal
          show={documentSubmitted}
          message={"Document Submitted"}
        />
      )}
    </React.Fragment>
  );
};

export default UserHome;
