import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import UserHome from "./pages/UserHome";
import AdminHome from "./pages/AdminHome";
import React, { useContext } from "react";
import UserDataContext from "./context/UserDataContext";
import Loading from "./components/Loading";
import AdminDataContext from "./context/AdminDataContext";
import ResetPass from "./pages/ResetPass";
import ResetPassConfirm from "./pages/ResetPassConfirm";

function App() {
  const {
    handlefetchAdminDataLoading,
    getAllApplicantsDataLoading,
    handleAcceptOrRejectEventLoading,
    handleBulkActionLoading,
    handleExamDateSubmitLoading,
  } = useContext(AdminDataContext);
  const {
    loginLoading,
    registerLoading,
    checkTokenLoading,
    fetchApplicantDataLoading,
    handleApplicationFormSubmitLoading,
    handleApplicantDocumentSubmitLoading,
    handlePassOrFailExamEventLoading,
    resetPasswordRequestLoading,
  } = useContext(UserDataContext);

  return (
    <React.Fragment>
      {loginLoading && <Loading />}
      {registerLoading && <Loading />}
      {checkTokenLoading && <Loading />}
      {fetchApplicantDataLoading && <Loading />}
      {handleApplicationFormSubmitLoading && <Loading />}
      {handleApplicantDocumentSubmitLoading && <Loading />}
      {handlefetchAdminDataLoading && <Loading />}
      {getAllApplicantsDataLoading && <Loading />}
      {handleAcceptOrRejectEventLoading && <Loading />}
      {handleBulkActionLoading && <Loading />}
      {handlePassOrFailExamEventLoading && <Loading />}
      {resetPasswordRequestLoading && <Loading />}
      {handleExamDateSubmitLoading && <Loading />}
      <Routes>
        <Route path="/userhome" element={<UserHome />} />
        <Route path="/adminhome" element={<AdminHome />} />
        <Route path="/reset-pass" element={<ResetPass />} />
        <Route path="/reset-password-confirm" element={<ResetPassConfirm />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Login />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
