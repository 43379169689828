import React, { useContext, useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import UserDataContext from "../context/UserDataContext";
import { BsFillTrashFill } from "react-icons/bs";

const DocUpload = () => {
  const [docInfoComplete, setDocInfoComplete] = useState(false);
  // const [allowMultiple, setAllowMultiple] = useState(false);
  const docUploadOptions = [
    "paymentreceipt",
    "passportphotograph",
    "birthcertificate",
    "baptismalcertificate",
    "lastsessionfirsttermreportcard",
    "lastsessionsecondtermreportcard",
    "lastsessionthirdtermreportcard",
    "currentsessionfirsttermreportcard",
  ];
  const {
    selectedFiles,
    setSelectedFiles,
    handleApplicantDocumentSubmit,
    uploadedFileNames,
    fetchUploadedFiles,
    documentFileValue,
    setDocumentFileValue,
  } = useContext(UserDataContext);
  const fileInputRef = useRef();

  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };

  // Function to handle file removal
  const handleRemoveFile = (fileToRemove) => {
    setSelectedFiles(selectedFiles.filter((file) => file !== fileToRemove));
  };

  // Function to trigger file selection dialog when SVG is clicked
  const handleUploadIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDocumentTypeChange = (event) => {
    const { value } = event.target;
    // if (value === "lastsessionthreereportcards") {
    //   setAllowMultiple(true);
    // } else {
    //   setAllowMultiple(false);
    // }
    setDocumentFileValue(value);
  };

  useEffect(() => {
    if (documentFileValue === "" && selectedFiles.length === 0) {
      setDocInfoComplete(false);
    } else if (documentFileValue !== "" && selectedFiles.length !== 0) {
      setDocInfoComplete(true);
    } else {
      setDocInfoComplete(false);
    }
  }, [documentFileValue, selectedFiles]);

  useEffect(() => {
    fetchUploadedFiles();
  }, [uploadedFileNames]);

  const uploadedNames = uploadedFileNames.map((item) => {
    const fileName = item.fileName.split("-")[0];
    console.log(fileName);
    return fileName;
  });

  const availableOptions = docUploadOptions.filter(
    (option) => !uploadedNames.includes(option)
  );

  // const handleApplicantDocumentSubmit = async () => {
  //   try {
  //     // Define the maximum file size (25MB in bytes)
  //     const maxFileSize = 25 * 1024 * 1024;

  //     // Filter out files that exceed the size limit
  //     const invalidFiles = selectedFiles.filter(
  //       (file) => file.size > maxFileSize
  //     );

  //     if (invalidFiles.length > 0) {
  //       console.error("File size exceeds the limit.");
  //       return;
  //     }

  //     const fileData = new FormData();

  //     selectedFiles.forEach((file) => {
  //       fileData.append("documents[]", file); // Use 'documents[]' to match the backend structure
  //     });

  //     // // Create an array to store file data
  //     // const fileData = [];

  //     // // Read each selected file as raw binary data
  //     // for (const file of selectedFiles) {
  //     //   const reader = new FileReader();
  //     //   reader.readAsArrayBuffer(file);
  //     //   await new Promise((resolve) => {
  //     //     reader.onload = () => {
  //     //       fileData.push({ name: file.name, data: reader.result });
  //     //       resolve();
  //     //     };
  //     //   });
  //     // }

  //     // Define the API endpoint URL for file upload
  //     const url =
  //       "http://localhost:8080/whitesandsback/apis/user/uploaddocuments";

  //     // Send a POST request with Axios, sending file data as raw binary
  //     const response = await axios.post(url, fileData, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         "Content-Type": "application/octet-stream", // Send as raw binary
  //       },
  //     });

  //     // Check the response and show a notification if successful
  //     if (response.data.success === true) {
  //       setDocumentSubmitted(true);
  //       setSelectedFiles([]);
  //       console.log("Document uploaded successfully");
  //     } else {
  //       console.error("Document not uploaded:", response.data.message);
  //     }
  //   } catch (err) {
  //     console.error("An error occurred:", err);
  //   }
  // };

  // const handleApplicantDocumentSubmit = async () => {
  //   try {
  //     // Define the maximum file size (25MB in bytes)
  //     const maxFileSize = 25 * 1024 * 1024;

  //     // Filter out files that exceed the size limit
  //     const invalidFiles = selectedFiles.filter(
  //       (file) => file.size > maxFileSize
  //     );

  //     if (invalidFiles.length > 0) {
  //       console.error("File size exceeds the limit.");
  //       return;
  //     }

  //     // Create a FormData object to send files
  //     const docData = new FormData();

  //     docData.append("documents", selectedFiles);

  //     // Append selected files to the FormData object
  //     // selectedFiles.forEach((file) => {
  //     //   formData.append("documents", file);
  //     // });

  //     // Display appended data (for debugging)
  //     for (const [key, value] of docData.entries()) {
  //       console.log(`Key: ${key}, Value: ${value}`);
  //     }

  //     // Define the API endpoint URL for file upload
  //     const url =
  //       "http://localhost:8080/whitesandsback/apis/uploadDocuments.php";

  //     // Send a POST request with Axios
  //     const response = await axios.post(url, docData, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         "Content-Type": "multipart/form-data", // Important for file uploads
  //       },
  //     });
  //     console.log(response);
  //     // Check the response and show a notification if successful
  //     if (response.data.success === true) {
  //       setCheckNotify(true);
  //       console.log("Document uploaded successfully");
  //     } else {
  //       console.error("Document not uploaded:", response.data.message);
  //     }
  //   } catch (err) {
  //     console.error("An error occurred:", err);
  //   }
  // };

  return (
    <div>
      <div className="row mt-4">
        <div className="col-xl-4 col-lg-6 col-md-10 my-2">
          <div className="customCard">
            {availableOptions.length > 0 ? (
              <div className="d-flex flex-column align-items-start h-100">
                <h6 className="mt-2 title">Upload documents</h6>
                <div className="mt-3 w-100">
                  <p>Please Upload all applicant documents</p>
                  <select
                    className="w-75 mb-3 py-2"
                    value={documentFileValue}
                    onChange={(event) => handleDocumentTypeChange(event)}
                    disabled={docInfoComplete}
                  >
                    <option value="">Select Document Type</option>
                    {availableOptions.map((option) => (
                      <option value={option} key={option}>
                        {option === "paymentreceipt"
                          ? "Digital copy of the bank transaction showing your payment"
                          : option === "passportphotograph"
                          ? "Recent passport photo of the candidate"
                          : option === "birthcertificate"
                          ? "Scanned copy of Birth Certificate"
                          : option === "baptismalcertificate"
                          ? "Scanned copy of Baptismal Certificate (for Catholics and Anglicans)"
                          : option === "lastsessionfirsttermreportcard"
                          ? "Scanned copy of report card for the first term of the previous school year (2022-23)"
                          : option === "lastsessionsecondtermreportcard"
                          ? "Scanned copy of report card for the second term of the previous school year (2022-23)"
                          : option === "lastsessionthirdtermreportcard"
                          ? "Scanned copy of report card for the third term of the previous school year (2022-23)"
                          : option === "currentsessionfirsttermreportcard"
                          ? "Scanned copy of report card for the first term of this present school year (2023-24)"
                          : ""}
                      </option>
                    ))}

                    {/* Add more options as needed */}
                  </select>
                  {documentFileValue && (
                    <React.Fragment>
                      <div
                        className="p-4 d-flex align-items-center justify-content-center mb-2"
                        style={{
                          borderRadius: "6px",
                          border: "1px solid #e5e5e5",
                          backgroundColor: "#E9EFFD",
                        }}
                      >
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                          disabled={docInfoComplete}
                        />

                        <Link to="#" onClick={handleUploadIconClick}>
                          <svg
                            width="54"
                            height="54"
                            viewBox="0 0 54 54"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M33.75 9H11.25V45H42.75V18H33.75V9ZM6.75 6.73155C6.75 5.49911 7.75685 4.5 8.99663 4.5H36L47.2493 15.75L47.25 47.2331C47.25 48.485 46.249 49.5 45.0148 49.5H8.98515C7.75071 49.5 6.75 48.4756 6.75 47.2684V6.73155ZM29.25 27V36H24.75V27H18L27 18L36 27H29.25Z"
                              fill="black"
                            />
                          </svg>
                        </Link>
                      </div>
                      <small
                        className="px-2"
                        style={{ color: "gray", fontSize: "10px" }}
                      >
                        Upload size limit: 25MB
                      </small>
                      <div className="selected-files-container">
                        {selectedFiles.map((file, index) => (
                          <div key={index} className="selected-file d-flex">
                            <span className="px-1">{file.name}</span>
                            <button
                              onClick={() => handleRemoveFile(file)}
                              style={{
                                border: "none",
                                backgroundColor: "transparent",
                              }}
                            >
                              <BsFillTrashFill style={{ color: "red" }} />
                            </button>
                          </div>
                        ))}
                      </div>
                    </React.Fragment>
                  )}

                  <div className="mt-3">
                    <input
                      type="submit"
                      name="btnNext"
                      className="btnNext"
                      value="Submit"
                      onClick={() =>
                        handleApplicantDocumentSubmit(documentFileValue)
                      }
                      disabled={docInfoComplete === false}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="d-flex flex-column align-items-start justify-content-between h-100">
                <h6 className="mb-5">Your documents are being reviewed</h6>
                <p>
                  You will receive an email for update and also keep checking
                  your spam folder and the portal
                </p>
                <p className="text-danger">
                  Please feel free to contact us at admissions@whitesands.org.ng
                  for any inquiry and complaint
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocUpload;
