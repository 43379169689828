import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import usePostData from "../hooks/usePostData";
import useGetData from "../hooks/useGetData";

const AdminDataContext = createContext();

export function AdminDataProvider({ children }) {
  const [adminData, setAdminData] = useState([]);
  const [applicantsData, setApplicantsData] = useState([]);
  const [filteredApplicantsData, setFilteredApplicantsData] = useState([]);
  const [showNotificationModal, setShowNotificationModal] = useState("");
  const [showCalendarModal, setShowCalendarModal] = useState("");
  const [selectedApplicants, setSelectedApplicants] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [currentApplicantsPage, setCurrentApplicantsPage] = useState(1);
  const [applicantsPageSize, setApplicantsPageSize] = useState(25);
  const [allowBulkActions, setAllowBulkAction] = useState("");
  const [bulkOptions, setBulkOption] = useState([]);
  const [examDate, setExamDate] = useState("2024-01-01");
  const [examStartTime, setExamStartTime] = useState("9:00am");
  const [examEndTime, setExamEndTime] = useState("9:00am");
  const [examVenue, setExamVenue] = useState(
    `Whitesands School,
     Blk 140, Whitesands Street, 
     Lekki Phase 1, Lagos`
  );
  const [examEditing, setExamEditing] = useState(false);

  const navigate = useNavigate();

  //Get Admin Data functionality

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  };

  const handlefetchAdminDataSuccess = (data) => {
    if (data.success) {
      setAdminData(data.data);
    }
  };

  const handlefetchAdminDataMutation = useGetData(
    "https://applications.whitesands.org.ng/whitesandsback/apis/admin/getadmindata",
    handlefetchAdminDataSuccess,
    config.headers
  );

  async function fetchAdminData() {
    try {
      await handlefetchAdminDataMutation.mutateAsync();
    } catch (error) {
      console.error("Error fetching applicant data:", error);
    }
  }

  //Check Token Expiration Functionality

  const handleAdminCheckTokenSuccess = (data) => {
    if (data.success) {
      const newAccessToken = data.accessToken;
      localStorage.setItem("accessToken", newAccessToken);
    }
  };

  const checkAdminTokenExpirationMutation = usePostData(
    "https://applications.whitesands.org.ng/whitesandsback/apis/user/refreshtokens",
    handleAdminCheckTokenSuccess
  );

  async function checkAdminTokenExpiration() {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      // No access token, nothing to check
      navigate("/");
      return;
    }

    const decodedToken = jwt_decode(accessToken);
    const currentTime = Date.now();

    if (currentTime >= decodedToken.exp * 1000) {
      // Token has expired, remove it from localStorage
      localStorage.removeItem("accessToken");

      // Check refreshToken expiration
      const refreshToken = localStorage.getItem("refreshToken");
      if (!refreshToken) {
        return;
      }

      const refreshDecodedToken = jwt_decode(refreshToken);
      if (currentTime >= refreshDecodedToken.exp * 1000) {
        // Refresh token has also expired, remove it from localStorage
        localStorage.removeItem("refreshToken");
        return;
      }

      // Use refreshToken to request a new accessToken

      const refreshData = new FormData();
      refreshData.append("refreshToken", refreshToken);

      try {
        // Make the POST request using the custom hook
        await checkAdminTokenExpirationMutation.mutateAsync(refreshData);
      } catch (error) {
        console.error("An error occurred during token validation:", error);
        // Handle error
      }
    }
  }

  //Get all Applicants functionality

  const getAllApplicantsDataSuccess = (data) => {
    if (data.success) {
      const startIndex = (currentApplicantsPage - 1) * applicantsPageSize;
      const endIndex = startIndex + applicantsPageSize;
      setApplicantsData(data.applicants);
      setFilteredApplicantsData(data.applicants);
    }
  };

  const getAllApplicantsDataMutation = useGetData(
    "https://applications.whitesands.org.ng/whitesandsback/apis/admin/getallapplicants",
    getAllApplicantsDataSuccess
  );

  async function getAllApplicantsData() {
    try {
      await getAllApplicantsDataMutation.mutateAsync();
    } catch (error) {
      console.error("Error fetching applicants data:", error);
    }
  }

  //Handle Pass Or Fail Exam Functionalities

  const passorfailexamconfig = {
    headers: {
      "Content-Type": "application/json", // Specify JSON content type
    },
  };

  const handlePassOrFailExamSuccess = (data) => {
    if (data.success) {
      setShowNotificationModal("Application Accepted Successfully");

      // Wait for two seconds and then reload the page
      setTimeout(() => {
        setShowNotificationModal("");
        window.location.reload();
      }, 2000);
    }
    if (data.success === false) {
      setShowNotificationModal(data.message);

      // Wait for two seconds and then reload the page
      setTimeout(() => {
        setShowNotificationModal("");
        window.location.reload();
      }, 2000);
    }
  };

  const handlePassOrFailExamMutation = usePostData(
    "https://applications.whitesands.org.ng/whitesandsback/apis/admin/passorfailexam",
    handlePassOrFailExamSuccess,
    passorfailexamconfig.headers
  );

  const handlePassOrFailExam = async (
    action,
    userId,
    title,
    surName,
    firstName,
    dateOfBirth,
    emailAddress,
    uac
  ) => {
    // // Create a new FormData object
    // const formData = new FormData();

    // // Append fields to the FormData object
    // formData.append("userId", userId);
    // formData.append("action", action);

    const dataToSend = {
      action: action,
      userId: userId,
      title: title,
      surName: surName,
      firstName: firstName,
      dateOfBirth: dateOfBirth,
      email: emailAddress,
      uac: uac,
    };

    // Send the API request to perform the action using Axios with the FormData object
    try {
      await handlePassOrFailExamMutation.mutateAsync(
        JSON.stringify(dataToSend)
      );
    } catch (error) {
      console.log("An error occurred", error);
    }
  };

  //Handle Accept Or Reject Functionalities

  const acceptorrejectconfig = {
    headers: {
      "Content-Type": "application/json", // Specify JSON content type
    },
  };

  const handleAcceptOrRejectEventSuccess = (data) => {
    if (data.success) {
      setShowNotificationModal("Application Accepted Successfully");

      // Wait for two seconds and then reload the page
      setTimeout(() => {
        setShowNotificationModal("");
        window.location.reload();
      }, 2000);
    } else if (data.success === false) {
      setShowNotificationModal(data.message);

      // Wait for two seconds and then reload the page
      setTimeout(() => {
        setShowNotificationModal("");
        window.location.reload();
      }, 2000);
    }
  };

  const handleAcceptOrRejectEventMutation = usePostData(
    "https://applications.whitesands.org.ng/whitesandsback/apis/admin/acceptorreject",
    handleAcceptOrRejectEventSuccess,
    acceptorrejectconfig.headers
  );

  const handleAcceptOrRejectEvent = async (
    action,
    userId,
    title,
    surName,
    firstName,
    dateOfBirth,
    emailAddress,
    uac
  ) => {
    // // Create a new FormData object
    // const formData = new FormData();

    // // Append fields to the FormData object
    // formData.append("userId", userId);
    // formData.append("action", action);

    const dataToSend = {
      action: action,
      userId: userId,
      title: title,
      surName: surName,
      firstName: firstName,
      dateOfBirth: dateOfBirth,
      email: emailAddress,
      uac: uac,
    };

    // Send the API request to perform the action using Axios with the FormData object
    try {
      await handleAcceptOrRejectEventMutation.mutateAsync(
        JSON.stringify(dataToSend)
      );
    } catch (error) {
      console.log("An error occurred", error);
    }
  };

  //Handling Bulk Action functionalities

  const bulkconfig = {
    headers: {
      "Content-Type": "application/json", // Specify JSON content type
    },
  };

  const handleBulkActionSuccess = (data) => {
    if (data.success) {
      // Handle the response here
      console.log(data);
      setShowNotificationModal(data.message);

      // Wait for two seconds and then reload the page
      setTimeout(() => {
        setShowNotificationModal("");
        window.location.reload();
      }, 2000);
    }
  };

  const handleBulkActionMutation = usePostData(
    "https://applications.whitesands.org.ng/whitesandsback/apis/admin/bulkaction",
    handleBulkActionSuccess,
    bulkconfig.headers
  );

  const handleBulkAction = async (action) => {
    let dataToSend;
    if (action === "send_bulk_exam") {
      dataToSend = {
        applicantsSelected: selectedApplicants,
        action: action,
        examVenue: examVenue,
        examDate: examDate,
        examStartTime: examStartTime,
        examEndTime: examEndTime,
      };
    } else {
      dataToSend = {
        applicantsSelected: selectedApplicants,
        action: action,
      };
    }

    try {
      await handleBulkActionMutation.mutateAsync(JSON.stringify(dataToSend));
    } catch (err) {
      // Handle any errors here
      console.error(err);
    }
  };

  //Handling Exam Date Submit

  const handleExamDateSuccess = (data) => {
    // Handle success response from the server, if needed
    if (data.success) {
      setShowNotificationModal("Exam date assigned successfully");

      // Wait for two seconds and then reload the page
      setTimeout(() => {
        setShowNotificationModal("");
        window.location.reload();
      }, 3000);
    } else if (data.success === false) {
      setShowNotificationModal(data.message);

      // Wait for two seconds and then reload the page
      setTimeout(() => {
        setShowNotificationModal("");
        window.location.reload();
      }, 3000);
    }
    // You can update state or perform additional actions based on the response
  };

  const handleExamDateSubmitMutation = usePostData(
    "https://applications.whitesands.org.ng/whitesandsback/apis/admin/examdatesubmit",
    handleExamDateSuccess,
    bulkconfig.headers
  );

  const handleExamDateSubmit = async (selectedUser) => {
    // Assume you have examDate and examTime in your state or variables

    // Make the API call to submit the exam date and time
    const requestData = {
      uac: selectedUser.uac,
      email: selectedUser.email,
      firstName: selectedUser.firstName,
      surName: selectedUser.surName,
      dob: selectedUser.dob,
      applyingTo: selectedUser.applyingTo,
      presentClass: selectedUser.presentClass,
      examVenue: examVenue,
      examDate: examDate,
      examStartTime: examStartTime,
      examEndTime: examEndTime,
    };
    console.log(requestData);
    // Execute the API call using the mutation hook
    try {
      await handleExamDateSubmitMutation.mutateAsync(
        JSON.stringify(requestData)
      );
    } catch (err) {
      console.log(err);
    }
  };

  //Handling Download All Doc in Zip

  const handleDownloadAllDocSuccess = (data) => {
    if (data.success === true) {
      // You can now use response.downloadLink to create a download link
      // For example, you can set it as the href attribute of a link or a button
      const downloadLink = document.createElement("a");
      downloadLink.href = data.downloadLink;
      downloadLink.download = data.downloadLink.split("/").pop();
      downloadLink.click();
      downloadLink.target = "_blank";
    }
  };

  const handleDownloadAllDocMutation = usePostData(
    "https://applications.whitesands.org.ng/whitesandsback/apis/admin/downloadalldoc",
    handleDownloadAllDocSuccess
  );

  const handleDownloadAllDoc = async (applicationId) => {
    const dataToSend = {
      applicationId: applicationId,
    };

    try {
      await handleDownloadAllDocMutation.mutateAsync(
        JSON.stringify(dataToSend)
      );
    } catch (err) {
      console.log(err);
    }
  };

  //Handling Approve Documents

  const handleApproveDocumentsSucccess = (data) => {
    if (data.success === true) {
      // You can now use response.downloadLink to create a download link
    }
  };

  const handleApproveDocumentsMutation = usePostData(
    "https://applications.whitesands.org.ng/whitesandsback/apis/admin/approvedocuments",
    handleApproveDocumentsSucccess
  );
  const handleApproveDocuments = async (
    email,
    applicationId,
    surName,
    firstName
  ) => {
    const dataToSend = {
      email: email,
      applicationId: applicationId,
      surName: surName,
      firstName: firstName,
    };

    try {
      await handleApproveDocumentsMutation.mutateAsync(
        JSON.stringify(dataToSend)
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AdminDataContext.Provider
      value={{
        adminData,
        setAdminData,
        applicantsData,
        setApplicantsData,
        filteredApplicantsData,
        setFilteredApplicantsData,
        fetchAdminData,
        handlefetchAdminDataLoading: handlefetchAdminDataMutation.isLoading,
        checkAdminTokenExpiration,
        checkAdminTokenExpirationLoading:
          checkAdminTokenExpirationMutation.isLoading,
        currentApplicantsPage,
        setCurrentApplicantsPage,
        applicantsPageSize,
        setApplicantsPageSize,
        getAllApplicantsData,
        getAllApplicantsDataLoading: getAllApplicantsDataMutation.isLoading,
        getAllApplicantsDataError: getAllApplicantsDataMutation.isError,
        showNotificationModal,
        setShowNotificationModal,
        showCalendarModal,
        setShowCalendarModal,
        handlePassOrFailExam,
        handlePassOrFailExamEventLoading:
          handlePassOrFailExamMutation.isLoading,
        handlePassOrFailExamEventError: handlePassOrFailExamMutation.isError,
        handleAcceptOrRejectEvent,
        handleAcceptOrRejectEventLoading:
          handleAcceptOrRejectEventMutation.isLoading,
        handleAcceptOrRejectEventError:
          handleAcceptOrRejectEventMutation.isError,
        selectedApplicants,
        setSelectedApplicants,
        allowBulkActions,
        setAllowBulkAction,
        handleBulkAction,
        bulkOptions,
        setBulkOption,
        handleBulkActionLoading: handleBulkActionMutation.isLoading,
        handleBulkActionError: handleBulkActionMutation.isError,
        selectedUser,
        setSelectedUser,
        examVenue,
        setExamVenue,
        examDate,
        setExamDate,
        examStartTime,
        setExamStartTime,
        examEndTime,
        setExamEndTime,
        examEditing,
        setExamEditing,
        handleExamDateSubmit,
        handleExamDateSubmitLoading: handleExamDateSubmitMutation.isLoading,
        handleDownloadAllDoc,
        handleApproveDocuments,
      }}
    >
      {children}
    </AdminDataContext.Provider>
  );
}

export default AdminDataContext;
