import React, { useState } from "react";

const Apply4 = ({
  goToNextPage,
  goToPreviousPage,
  formData,
  setFormData,
  formError,
  setFormError,
  otherArea,
  setOtherArea,
  otherNationality,
  setOtherNationality,
}) => {
  const handleApplicantArea = (event) => {
    if (event.target.value !== "other") {
      setFormData((prevData) => ({
        ...prevData,
        applicantAreaofLagos: event.target.value,
        otherAreaofLagos: "",
      }));
      setOtherArea(false);
    } else if (event.target.value === "other") {
      setFormData((prevData) => ({
        ...prevData,
        otherAreaofLagos: "",
        applicantAreaofLagos: event.target.value,
      }));
      setOtherArea(true);
    }
  };

  const handleApplicantNationality = (event) => {
    if (event.target.value !== "other") {
      setFormData((prevData) => ({
        ...prevData,
        applicantNationality: event.target.value,
        otherNationality: "",
      }));
      setOtherNationality(false);
    } else if (event.target.value === "other") {
      setFormData((prevData) => ({
        ...prevData,
        otherNationality: "",
        applicantNationality: event.target.value,
      }));
      setOtherNationality(true);
    }
  };

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setFormError({
      ...formError,
      [event.target.name]: event.target.value ? "" : "This Field is required",
    });
  };

  const handleNextClick = (event) => {
    event.preventDefault();
    let isFormValid = true;

    if (!formData.applicantHomeAddress) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        applicantHomeAddress: "Home Address is required",
      }));
      isFormValid = false;
    }
    if (!formData.applicantAreaofLagos) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        applicantAreaofLagos: "Area of Lagos is required",
      }));
      isFormValid = false;
    }
    if (otherArea) {
      if (!formData.otherAreaofLagos) {
        setFormError((prevErrors) => ({
          ...prevErrors,
          otherArea: "Area of Lagos is required",
        }));
        isFormValid = false;
      }
    }
    if (otherNationality) {
      if (!formData.otherNationality) {
        setFormError((prevErrors) => ({
          ...prevErrors,
          otherArea: "Area of Lagos is required",
        }));
        isFormValid = false;
      }
    }
    if (!formData.applicantNationality) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        applicantNationality: "Nationality is required",
      }));
      isFormValid = false;
    }
    if (!formData.applicantPlaceofBirth) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        applicantPlaceofBirth: "Place of birth is required",
      }));
      isFormValid = false;
    }

    if (!isFormValid) {
      console.log("Form Error");
      return;
    }
    console.log(formData);
    goToNextPage();
  };
  return (
    <div className="row mt-4">
      <div className="col-xl-4 col-lg-6 col-md-10 my-2">
        <div className="customCard userHomeStatus">
          <div className="d-flex flex-column align-items-start">
            <h6 className="mt-2 title">NEW APPLICATION</h6>
            <div className="mt-4 w-100">
              <form action="">
                <div className="form-group my-2">
                  <label htmlFor="applicantHomeAddress" className="mb-2">
                    Home Address{" "}
                    {formError.applicantHomeAddress && (
                      <small className="px-1 py-2 text-danger">
                        {formError.applicantHomeAddress}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <input
                      id="applicantHomeAddress"
                      type="text"
                      name="applicantHomeAddress"
                      className="w-100 px-3 py-1 inputElement bg-white"
                      value={formData.applicantHomeAddress}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </div>
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="applicantAreaofLagos" className="mb-2">
                    Area of Lagos{" "}
                    {formError.applicantAreaofLagos && (
                      <small className="px-1 py-2 text-danger">
                        {formError.applicantAreaofLagos}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <select
                      className="w-100 inputElement px-2 py-2"
                      name="applicantAreaofLagos"
                      id="applicantAreaofLagos"
                      defaultValue={formData.applicantAreaofLagos}
                      onChange={(event) => handleApplicantArea(event)}
                    >
                      <option value="">Select Area</option>
                      <option value="abijo-gra">Abijo G.R.A</option>
                      <option value="agege">Agege</option>
                      <option value="aguda">Aguda</option>
                      <option value="agungi">Agungi</option>
                      <option value="ajah">Ajah</option>
                      <option value="ajao-estate">Ajao Estate</option>
                      <option value="amuwo-odofin">Amuwo Odofin</option>
                      <option value="anthony-village">Anthony Village</option>
                      <option value="apapa">Apapa</option>
                      <option value="awoyaya">Awoyaya</option>
                      <option value="badore">Badore</option>
                      <option value="chevron-area">Chevron Area</option>
                      <option value="ebutte-metta">Ebutte Metta</option>
                      <option value="egbeda">egbeda</option>
                      <option value="elegushi">Elegushi</option>
                      <option value="festac">Festac</option>
                      <option value="gbagada">Gbagada</option>
                      <option value="idi-araba">Idi Araba</option>
                      <option value="ikeja">Ikeja</option>
                      <option value="ikotun">Ikotun</option>
                      <option value="ikoyi">Ikoyi</option>
                      <option value="ilupeju">Ilupeju</option>
                      <option value="isolo">Isolo</option>
                      <option value="langbasa">Langbasa</option>
                      <option value="lekki-phaseone">Lekki Phase-One</option>
                      <option value="magodo">Magodo</option>
                      <option value="maryland">Maryland</option>
                      <option value="nicon-estate">Nicon Estate</option>
                      <option value="ogba">Ogba</option>
                      <option value="ogudu">Ogudu</option>
                      <option value="ojodu">Ojodu</option>
                      <option value="okota">Okota</option>
                      <option value="oregun">Oregun</option>
                      <option value="osapa">Osapa</option>
                      <option value="oshodi">Oshodi</option>
                      <option value="sangoted">Sangotedo</option>
                      <option value="shasha">Shasha</option>
                      <option value="shomolu">Shomolu</option>
                      <option value="surulere">Surulere</option>
                      <option value="victor-gardencity">
                        Victor Garden City
                      </option>
                      <option value="victoria-island">Victoria Island</option>
                      <option value="yaba">Yaba</option>
                      <option value="other">Other</option>
                    </select>
                    <div className="input-group-postpend">
                      <span
                        className="input-group-text h-100 position-absolute"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          zIndex: 10,
                          transform: "translateX(-1.75rem)",
                        }}
                      >
                        <i>
                          <svg
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.2021 0.450301L7.75177 13.3546L0.301465 0.450301L15.2021 0.450301Z"
                              fill="#D9D9D9"
                            ></path>
                          </svg>
                        </i>
                      </span>
                    </div>
                  </div>
                </div>
                {otherArea && (
                  <div className="form-group my-2">
                    <label htmlFor="otherAreaofLagos" className="mb-2">
                      Type In Applicant's Area{" "}
                      {formError.otherAreaofLagos && (
                        <small className="px-1 py-2 text-danger">
                          {formError.otherAreaofLagos}
                        </small>
                      )}
                    </label>
                    <div className="input-group">
                      <input
                        id="otherAreaofLagos"
                        type="text"
                        name="otherAreaofLagos"
                        className="w-100 px-3 py-1 inputElement bg-white"
                        value={formData.otherAreaofLagos}
                        onChange={(event) => handleInputChange(event)}
                      />
                    </div>
                  </div>
                )}
                <div className="form-group mb-2 d-flex flex-column align-items-start justify-content-between">
                  <label htmlFor="applicantNationality" className="mb-2">
                    Applicant's Nationality{" "}
                    {formError.applicantNationality && (
                      <small className="px-1 py-2 text-danger">
                        {formError.applicantNationality}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <select
                      className="w-100 inputElement px-2 py-2"
                      name="applicantNationality"
                      id="applicantNationality"
                      defaultValue={formData.applicantNationality}
                      onChange={(event) => handleApplicantNationality(event)}
                    >
                      <option value="">Select Nationality</option>
                      <option value="nigerian">Nigerian</option>
                      <option value="other">Other</option>
                    </select>
                    <div className="input-group-postpend">
                      <span
                        className="input-group-text h-100 position-absolute"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          zIndex: 10,
                          transform: "translateX(-1.75rem)",
                        }}
                      >
                        <i>
                          <svg
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.2021 0.450301L7.75177 13.3546L0.301465 0.450301L15.2021 0.450301Z"
                              fill="#D9D9D9"
                            ></path>
                          </svg>
                        </i>
                      </span>
                    </div>
                  </div>
                </div>
                {otherNationality && (
                  <div className="form-group my-2">
                    <label htmlFor="otherNationality" className="mb-2">
                      Type In Applicant's Nationality{" "}
                      {formError.otherNationality && (
                        <small className="px-1 py-2 text-danger">
                          {formError.otherNationality}
                        </small>
                      )}
                    </label>
                    <div className="input-group">
                      <input
                        id="otherNationality"
                        type="text"
                        name="otherNationality"
                        className="w-100 px-3 py-1 inputElement bg-white"
                        value={formData.otherNationality}
                        onChange={(event) => handleInputChange(event)}
                      />
                    </div>
                  </div>
                )}
                <div className="form-group mb-2">
                  <label htmlFor="applicantPlaceofBirth" className="mb-2">
                    Place of Birth{" "}
                    {formError.applicantPlaceofBirth && (
                      <small className="px-1 py-2 text-danger">
                        {formError.applicantPlaceofBirth}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <input
                      id="applicantPlaceofBirth"
                      type="text"
                      name="applicantPlaceofBirth"
                      className="py-1 w-100 px-3 inputElement bg-white"
                      value={formData.applicantPlaceofBirth}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <div className="form-group mt-3">
                    <input
                      type="button"
                      name="btnPrev"
                      className="btnPrev"
                      value="Back"
                      onClick={goToPreviousPage}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <input
                      type="button"
                      name="btnNext"
                      className="btnNext"
                      value="Next"
                      onClick={(event) => handleNextClick(event)}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Apply4;
