import React, { useContext } from "react";
import userPic from "../assets/images/userPic.png";
import { Nav, Image } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import UserDataContext from "../context/UserDataContext";

const TopNav = ({ applicantData }) => {
  const { handleLogout } = useContext(UserDataContext);

  return (
    <header className="userHomeTopNav d-flex justify-content-end w-100 align-items-center">
      <Dropdown
        style={{
          backgroundColor: "#4fadea",
          borderRadius: "6px",
          padding: "5px",
        }}
      >
        <Dropdown.Toggle
          as={Nav.Link}
          id="dropdown-basic"
          type="btn"
          className="btn d-flex align-items-center"
          style={{ gap: "0.5rem" }}
          variant="primary"
        >
          <Image src={userPic} alt="" style={{ width: "40px" }} />
          <span className="text-black">Welcome {applicantData.lastName}, </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="#/action-1">Account Settings</Dropdown.Item>
          <Dropdown.Item href="#/action-2">Profile</Dropdown.Item>
          <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </header>
  );
};

export default TopNav;
