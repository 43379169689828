import React, { useContext, useEffect, useState } from "react";
import NavTab from "../components/NavTab";
import TopNav from "../components/TopNav";
import AdminSideBar from "../components/AdminSideBar";
import AdminMainContent from "../components/AdminMainContent";
import UserInfoModal from "../components/Modals/UserInfoModal";
import NotificationModal from "../components/Modals/NotificationModal";
import CalenderModal from "../components/Modals/CalenderModal";
import AdminDataContext from "../context/AdminDataContext";

const AdminHome = () => {
  const [sidebarContentSelect, setsidebarContentSelect] = useState("dashboard");
  const [userInfoModal, setUserInfoModal] = useState(false);

  const [dateSubmit, setDateSubmit] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const {
    adminData,
    fetchAdminData,
    checkAdminTokenExpiration,
    getAllApplicantsData,
    applicantsData,
    showNotificationModal,
    showCalendarModal,
    setShowCalendarModal,
    setShowNotificationModal,
    selectedUser,
    handleDownloadAllDoc,
    setCurrentApplicantsPage,
    setFilteredApplicantsData,
    setAllowBulkAction,
    setBulkOption,
  } = useContext(AdminDataContext);
  const handleClick = (param) => {
    if (sidebarContentSelect === param) {
      return;
    } else {
      setsidebarContentSelect(param);
      setCurrentApplicantsPage(1);
      setFilteredApplicantsData(applicantsData);
      setAllowBulkAction("");
      setBulkOption([]);
    }
  };

  useEffect(() => {
    checkAdminTokenExpiration();
    fetchAdminData(); // Call the fetch function when UserHome mounts
    getAllApplicantsData(); // Call the fetch function when UserHome mounts
  }, []);

  setInterval(checkAdminTokenExpiration, 60000); // Check every minute

  return (
    <React.Fragment>
      <div className="wrapper">
        <TopNav applicantData={adminData} />
        <div className="main-container">
          <AdminSideBar
            handleClick={handleClick}
            sidebarContentSelect={sidebarContentSelect}
          />
          <AdminMainContent
            sidebarContentSelect={sidebarContentSelect}
            applicantsData={applicantsData}
            selectedApplicant={selectedApplicant}
            setSelectedApplicant={setSelectedApplicant}
            userInfoModal={userInfoModal}
            setUserInfoModal={setUserInfoModal}
            showNotificationModal={showNotificationModal}
            setShowNotificationModal={setShowNotificationModal}
            setShowCalendarModal={setShowCalendarModal}
          />
        </div>
      </div>
      {userInfoModal && (
        <UserInfoModal
          userInfoModal={userInfoModal}
          handleClose={() => setUserInfoModal(false)}
          applicantData={selectedApplicant}
          handleDownloadAllDoc={handleDownloadAllDoc}
        />
      )}
      {showNotificationModal !== "" && (
        <NotificationModal
          show={showNotificationModal !== ""}
          message={showNotificationModal}
        />
      )}
      {showCalendarModal !== "" && (
        <CalenderModal
          showCalendarModal={showCalendarModal}
          setShowCalendarModal={setShowCalendarModal}
          setDateSubmit={setDateSubmit}
          selectedUser={selectedUser}
        />
      )}
      {dateSubmit && (
        <NotificationModal show={dateSubmit} message={"Interview Date Sent"} />
      )}
    </React.Fragment>
  );
};

export default AdminHome;
