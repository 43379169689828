import React from "react";

const Loading = () => {
  return (
    <div className="loading">
      <div className="loading-backdrop"></div>
      <div className="loading-spinner">
        {/* You can use a loading spinner here */}
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    </div>
  );
};

export default Loading;
