import { useEffect, useState } from "react";

const NotificationModal = ({ show, message }) => {
  const [isVisible, setisVisible] = useState(show);
  useEffect(() => {
    setisVisible(show);
    if (show) {
      const timer = setTimeout(() => {
        setisVisible(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [show]);
  return (
    isVisible && (
      <div className="notifcationModal">
        <div className="d-flex w-100 h-100 justify-content-between align-items-center px-2">
          <i>
            <svg
              width="34"
              height="26"
              viewBox="0 0 34 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 13.8348L11.6431 25.5001L34 3.16532L30.7903 0L11.6431 19.125L3.16525 10.6472L0 13.8348Z"
                fill="#3FC59D"
              />
            </svg>
          </i>
          <p className="notificationModalMessage mb-0">{message}</p>
          <p className="mb-0">Dismiss</p>
        </div>
      </div>
    )
  );
};

export default NotificationModal;
