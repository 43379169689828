export function capitalizeString(str) {
  // Check if the input string is not empty
  if (str && typeof str === "string") {
    // Capitalize the first letter and concatenate it with the rest of the string
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    // Handle empty or non-string inputs
    return "String Not found";
  }
}
