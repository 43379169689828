import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AdminDataContext from "../../context/AdminDataContext";

const CalenderModal = ({ setDateSubmit, selectedUser }) => {
  const {
    examVenue,
    setExamVenue,
    examDate,
    setExamDate,
    examStartTime,
    setExamStartTime,
    examEndTime,
    setExamEndTime,
    examEditing,
    setExamEditing,
    handleBulkAction,
    showCalendarModal,
    handleExamDateSubmit,
    setShowCalendarModal,
  } = useContext(AdminDataContext);
  const [selectedDates, setSelectedDates] = useState([]);

  const handleExamEditClick = () => {
    setExamEditing(true);
  };

  const handleExamDateChange = (event) => {
    setExamDate(event.target.value);
  };

  const handleExamStartTimeChange = (event) => {
    setExamStartTime(event.target.value);
  };

  const handleExamEndTimeChange = (event) => {
    setExamEndTime(event.target.value);
  };

  const handleExamVenueChange = (event) => {
    setExamVenue(event.target.value);
  };

  const handleDateChange = (date) => {
    // Convert the selected date from string to a Date object
    const selectedDate = new Date(date);

    // Check if the selected date already exists in the array
    if (selectedDates.some((d) => d.getTime() === selectedDate.getTime())) {
      // Date is already selected, remove it from the array (toggle off)
      setSelectedDates(
        selectedDates.filter((d) => d.getTime() !== selectedDate.getTime())
      );
    } else if (selectedDates.length < 3) {
      // Date is not already selected and there's room for more selections, add it to the array (toggle on)
      setSelectedDates([...selectedDates, selectedDate]);
    }
  };

  const handleRemoveDate = (dateToRemove) => {
    setSelectedDates(selectedDates.filter((date) => date !== dateToRemove));
  };

  const handleDateSubmit = () => {
    // Validate selectedDates before submitting
    if (
      selectedDates.length === 3 &&
      selectedDates.every((date) => date instanceof Date)
    ) {
      // Send the selectedDates array to the server
      // You can use a fetch or Axios request to send the data to the server
      console.log(selectedDates);
      setShowCalendarModal("");
      setDateSubmit(true);
    } else {
      console.error("Invalid selected dates:", selectedDates);
    }
  };

  const today = new Date();

  const highlightSelectedDates = (date) => {
    const isSelected = selectedDates.some((selectedDate) => {
      return (
        date.getFullYear() === selectedDate.getFullYear() &&
        date.getMonth() === selectedDate.getMonth() &&
        date.getDate() === selectedDate.getDate()
      );
    });

    return isSelected ? "selected-date" : null;
  };

  return (
    <Modal
      className="justify-content-center h-100"
      show={showCalendarModal !== ""}
    >
      <Modal.Header>
        {showCalendarModal === "interview" && (
          <Modal.Title style={{ width: "100%" }}>
            <h4 style={{ textAlign: "center" }}>Interview Invite</h4>
          </Modal.Title>
        )}{" "}
        {showCalendarModal === "send_exam" && (
          <Modal.Title style={{ width: "100%" }}>
            <h4 style={{ textAlign: "center" }}>Exam Information</h4>
          </Modal.Title>
        )}
        {showCalendarModal === "send_bulk_exam" && (
          <Modal.Title style={{ width: "100%" }}>
            <h4 style={{ textAlign: "center" }}>Exam Information</h4>
          </Modal.Title>
        )}
      </Modal.Header>
      {showCalendarModal === "interview" && (
        <Modal.Body>
          <div className="dateContainer">
            <DatePicker
              selected={null}
              onChange={handleDateChange}
              inline
              minDate={today}
              // maxDate={new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000)}
              selectsRange
              // startDate={startDate}
              // endDate={endDate}
              dayClassName={highlightSelectedDates}
            />
          </div>
          <div className="selected-dates-container">
            {selectedDates.map((date, index) => (
              <span key={date.getTime()} className="selected-date">
                {date.toDateString()}
                <button onClick={() => handleRemoveDate(date)}>Remove</button>
              </span>
            ))}
          </div>
        </Modal.Body>
      )}
      {showCalendarModal === "send_exam" && (
        <Modal.Body>
          <div className="exam-info-container">
            <form>
              <div className="form-group d-flex mb-3">
                <label>Exam Venue: </label>
                {examEditing ? (
                  <div className="px-3">
                    <input
                      type="text"
                      value={examVenue}
                      onChange={handleExamVenueChange}
                    />
                  </div>
                ) : (
                  <p className="px-2">
                    <strong>{examVenue}</strong>
                  </p>
                )}
              </div>
              <div className="form-group d-flex mb-3">
                <label>Exam Date: </label>
                {examEditing ? (
                  <div className="px-3">
                    <input
                      type="date"
                      value={examDate}
                      onChange={handleExamDateChange}
                    />
                  </div>
                ) : (
                  <div className="px-3">
                    <input
                      className="px-2"
                      type="text"
                      value={examDate}
                      readOnly
                    />
                  </div>
                )}
              </div>
              <div className="form-group d-flex mb-3">
                <label>Exam Start: </label>
                {examEditing ? (
                  <div className="px-3">
                    <input
                      type="time"
                      value={examStartTime}
                      onChange={handleExamStartTimeChange}
                    />
                  </div>
                ) : (
                  <div className="px-3">
                    <input type="text" value={examStartTime} readOnly />
                  </div>
                )}
              </div>
              <div className="form-group d-flex">
                <label>Exam End: </label>
                {examEditing ? (
                  <div className="px-3">
                    <input
                      type="time"
                      value={examEndTime}
                      onChange={handleExamEndTimeChange}
                    />
                  </div>
                ) : (
                  <div className="px-4">
                    <input type="text" value={examEndTime} readOnly />
                  </div>
                )}
              </div>
              {examEditing ? (
                <button
                  className="mt-2 px-3 py-2"
                  type="button"
                  onClick={() => setExamEditing(false)}
                >
                  Save
                </button>
              ) : (
                <button
                  className="mt-2 px-3 py-1"
                  type="button"
                  onClick={handleExamEditClick}
                  style={{ borderRadius: "6px" }}
                >
                  Edit
                </button>
              )}
            </form>
          </div>
        </Modal.Body>
      )}
      {showCalendarModal === "send_bulk_exam" && (
        <Modal.Body>
          <div className="exam-info-container">
            <form>
              <div className="form-group d-flex mb-3">
                <label>Exam Venue: </label>
                {examEditing ? (
                  <div className="px-3">
                    <input
                      type="text"
                      value={examVenue}
                      onChange={handleExamVenueChange}
                    />
                  </div>
                ) : (
                  <p className="px-2">
                    <strong>{examVenue}</strong>
                  </p>
                )}
              </div>
              <div className="form-group d-flex mb-3">
                <label>Exam Date: </label>
                {examEditing ? (
                  <div className="px-3">
                    <input
                      type="date"
                      value={examDate}
                      onChange={handleExamDateChange}
                    />
                  </div>
                ) : (
                  <div className="px-3">
                    <input
                      className="px-2"
                      type="text"
                      value={examDate}
                      readOnly
                    />
                  </div>
                )}
              </div>
              <div className="form-group d-flex mb-3">
                <label>Exam Start: </label>
                {examEditing ? (
                  <div className="px-3">
                    <input
                      type="time"
                      value={examStartTime}
                      onChange={handleExamStartTimeChange}
                    />
                  </div>
                ) : (
                  <div className="px-3">
                    <input type="text" value={examStartTime} readOnly />
                  </div>
                )}
              </div>
              <div className="form-group d-flex">
                <label>Exam End: </label>
                {examEditing ? (
                  <div className="px-3">
                    <input
                      type="time"
                      value={examEndTime}
                      onChange={handleExamEndTimeChange}
                    />
                  </div>
                ) : (
                  <div className="px-4">
                    <input type="text" value={examEndTime} readOnly />
                  </div>
                )}
              </div>
              {examEditing ? (
                <button
                  className="mt-2 px-3 py-2"
                  type="button"
                  onClick={() => setExamEditing(false)}
                >
                  Save
                </button>
              ) : (
                <button
                  className="mt-2 px-3 py-1"
                  type="button"
                  onClick={handleExamEditClick}
                  style={{ borderRadius: "6px" }}
                >
                  Edit
                </button>
              )}
            </form>
          </div>
        </Modal.Body>
      )}

      <Modal.Footer className="justify-content-evenly">
        <button
          className=""
          style={{ border: "none", backgroundColor: "transparent" }}
          onClick={() => setShowCalendarModal("")}
        >
          Cancel
        </button>
        {showCalendarModal === "send_exam" && (
          <button
            className="btnNext small"
            onClick={() =>
              handleExamDateSubmit(
                selectedUser,
                examDate,
                examStartTime,
                examEndTime,
                examVenue
              )
            }
          >
            Submit
          </button>
        )}
        {showCalendarModal === "send_bulk_exam" && (
          <button
            className="btnNext small"
            onClick={() => handleBulkAction(showCalendarModal)}
          >
            Submit
          </button>
        )}
        {showCalendarModal === "interview" && (
          <button className="btnNext small" onClick={handleDateSubmit}>
            Submit
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CalenderModal;
