import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isValidEmail, isValidPassword } from "../utils/FormValidators";
import jwt_decode from "jwt-decode";
import usePostData from "../hooks/usePostData";
import useGetData from "../hooks/useGetData";
import { arraysAreEqual } from "../utils/arraysAreEqual";

const UserDataContext = createContext();

export function UserDataProvider({ children }) {
  const [sidebarContentSelect, setsidebarContentSelect] = useState("dashboard");
  const [showSubmitModal, setShowSubmitModal] = useState("");
  const [registrationFailed, setRegistrationFailed] = useState("");
  const [loginFailed, setLoginFailed] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [resetEmail, setResetEmail] = useState("");
  const [resetLinkSent, setResetLinkSent] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setconfirmNewPassword] = useState("");
  const [resetTokenVerified, setResetTokenVerified] = useState(false);
  const [resetPassError, setResetPassError] = useState("");
  const [resetSuccessful, setResetSuccessful] = useState(false);
  const [uploadedFileNames, setUploadedFileNames] = useState([]);
  const [documentFileValue, setDocumentFileValue] = useState("");
  const [documentSubmitted, setDocumentSubmitted] = useState(false);
  const [applicantData, setApplicantData] = useState([]);
  const [newlyRegistered, setNewlyRegistered] = useState(false);
  const navigate = useNavigate();

  const [registerInfo, setRegisterInfo] = useState({
    lastName: "",
    firstName: "",
    emailAddress: "",
    password: "",
  });
  const [registerError, setRegisterError] = useState({
    lastName: "",
    firstName: "",
    emailAddress: "",
    password: "",
  });
  const [loginInfo, setLoginInfo] = useState({
    emailAddress: "",
    userPassword: "",
  });
  const [loginError, setLoginError] = useState("");

  const [applicationStatus, setApplicationStatus] = useState("");

  //Check Token Expiration

  const handleCheckTokenSuccess = (data) => {
    if (data.success) {
      const newAccessToken = data.accessToken;
      localStorage.setItem("accessToken", newAccessToken);
    }
  };

  const checkTokenMutation = usePostData(
    "https://applications.whitesands.org.ng/whitesandsback/apis/user/refreshtokens",
    handleCheckTokenSuccess
  );

  async function checkTokenExpiration() {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      // No access token, nothing to check
      navigate("/");
      return;
    }

    const decodedToken = jwt_decode(accessToken);
    const currentTime = Date.now();

    if (currentTime >= decodedToken.exp * 1000) {
      // Token has expired, remove it from localStorage
      localStorage.removeItem("accessToken");

      // Check refreshToken expiration
      const refreshToken = localStorage.getItem("refreshToken");
      if (!refreshToken) {
        return;
      }

      const refreshDecodedToken = jwt_decode(refreshToken);
      if (currentTime >= refreshDecodedToken.exp * 1000) {
        // Refresh token has also expired, remove it from localStorage
        localStorage.removeItem("refreshToken");
        return;
      }

      // Use refreshToken to request a new accessToken

      const refreshData = new FormData();
      refreshData.append("refreshToken", refreshToken);
      try {
        // Make the POST request using the custom hook
        await checkTokenMutation.mutateAsync(refreshData);
      } catch (error) {
        console.error("An error occurred during token validation:", error);
        // Handle error
      }
    }
  }

  //Register Functionalities
  const handleRegisterSuccess = (data) => {
    if (data.success === true) {
      setNewlyRegistered(true);
      setRegisterInfo({
        lastName: "",
        firstName: "",
        emailAddress: "",
        password: "",
      });
      setRegisterError({
        lastName: "",
        firstName: "",
        emailAddress: "",
        password: "",
      });
      navigate("/login");
    } else if (data.success === false) {
      setRegisterInfo({
        lastName: "",
        firstName: "",
        emailAddress: "",
        password: "",
      });
      setRegisterError({
        lastName: "",
        firstName: "",
        emailAddress: "",
        password: "",
      });
      setRegistrationFailed(data.message);
    }
  };

  const registerMutation = usePostData(
    "https://applications.whitesands.org.ng/whitesandsback/apis/user/registration",
    handleRegisterSuccess
  );

  const handleRegisterSubmit = async (event) => {
    event.preventDefault();
    setRegistrationFailed("");
    let isFormValid = true;
    setRegisterError({});
    if (!registerInfo.lastName) {
      setRegisterError((prevError) => ({
        ...prevError,
        lastName: "Last name / Surname is required",
      }));
      isFormValid = false;
    }
    if (!registerInfo.firstName) {
      setRegisterError((prevError) => ({
        ...prevError,
        firstName: "First name is required",
      }));
      isFormValid = false;
    }
    if (!registerInfo.emailAddress) {
      setRegisterError((prevError) => ({
        ...prevError,
        emailAddress: "Email address is required",
      }));
      isFormValid = false;
    }
    if (!isValidEmail(registerInfo.emailAddress)) {
      setRegisterError((prevError) => ({
        ...prevError,
        emailAddress: "Please enter a valid email address",
      }));
      isFormValid = false;
    }
    if (!registerInfo.password) {
      setRegisterError((prevError) => ({
        ...prevError,
        password: "Password is required",
      }));
      isFormValid = false;
    }
    if (!isValidPassword(registerInfo.password)) {
      setRegisterError((prevError) => ({
        ...prevError,
        password: "Please enter a valid password",
      }));
      isFormValid = false;
    }

    if (!isFormValid) {
      return;
    }
    const registerDataToSubmit = new FormData();
    for (const key in registerInfo) {
      registerDataToSubmit.append(key, registerInfo[key]);
    }

    try {
      // Make the POST request using the custom hook
      await registerMutation.mutateAsync(registerDataToSubmit);
    } catch (error) {
      console.error("An error occurred during registration:", error);
      // Handle error
    }
  };

  //Login Functionalities

  const handleLoginSuccess = (data) => {
    if (data.success === true) {
      // Handle successful login response here
      setLoginInfo({
        emailAddress: "",
        userPassword: "",
      });
      setLoginError({
        emailAddress: "",
        userPassword: "",
      });
      const accessToken = data.accessToken;
      const refreshToken = data.refreshToken;
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      const decodedToken = jwt_decode(accessToken);

      if (decodedToken) {
        const tokenExpiration = decodedToken.exp * 1000; // Convert to milliseconds
        if (Date.now() < tokenExpiration) {
          // Token is still valid
          if (decodedToken.usercat === "admin") {
            navigate("/adminhome");
          } else {
            navigate("/userhome");
          }
          setNewlyRegistered(false);
        } else {
          // Remove expired token from localStorage
          // Handle token expiration
          console.log("Token removed");
        }
      } else {
        console.log("Invalid token");
        // Handle invalid token
      }
    } else if (data.success === false) {
      setLoginInfo({
        emailAddress: "",
        userPassword: "",
      });
      setLoginError({
        emailAddress: "",
        userPassword: "",
      });
      setLoginFailed(data.message);
    }
  };

  const loginMutation = usePostData(
    "https://applications.whitesands.org.ng/whitesandsback/apis/user/login",
    handleLoginSuccess
  );

  const handleLoginSubmit = async (event) => {
    event.preventDefault();

    if (!loginInfo.emailAddress || !loginInfo.userPassword) {
      setLoginError("User ID or Password is not valid");
      return;
    }

    const loginDataToSubmit = new FormData();
    for (const key in loginInfo) {
      loginDataToSubmit.append(key, loginInfo[key]);
    }

    try {
      // Make the POST request using the custom hook
      await loginMutation.mutateAsync(loginDataToSubmit);
    } catch (error) {
      console.error("An error occurred during login:", error);
      // Handle error
    }
  };

  //Reset Password Request

  const handleResetPasswordRequestSuccess = (data) => {
    if (data.success === true) {
      setResetLinkSent(true);
      setResetEmail("");
    }
  };

  const resetPasswordRequestMutation = usePostData(
    "https://applications.whitesands.org.ng/whitesandsback/apis/user/reset-password-request",
    handleResetPasswordRequestSuccess
  );

  const handleResetPasswordRequest = async (event) => {
    event.preventDefault();

    const dataToSend = {
      resetEmail: resetEmail,
    };

    try {
      await resetPasswordRequestMutation.mutateAsync(
        JSON.stringify(dataToSend)
      );
    } catch (err) {
      alert(err);
    }
  };

  //Verify Reset Token

  const verifyResetTokenSuccess = (data) => {
    if (data.success === true) {
      setResetTokenVerified(true);
    } else {
      setResetPassError(data.message);
      alert(data.message);

      navigate("/");
    }
  };

  const verifyResetTokenMutation = usePostData(
    "https://applications.whitesands.org.ng/whitesandsback/apis/user/verify-reset-token",
    verifyResetTokenSuccess
  );

  const verifyResetToken = async (email, reset_code) => {
    const dataToSend = {
      emailAddress: email,
      resetCode: reset_code.toString(),
    };
    console.log(dataToSend);

    try {
      await verifyResetTokenMutation.mutateAsync(JSON.stringify(dataToSend));
    } catch (err) {}
  };

  //Set New Password
  const handleSetNewPasswordSuccess = (data) => {
    if (data.success === true) {
      setResetSuccessful(true);
      navigate("/");
    }
  };

  const handleSetNewPasswordMutation = usePostData(
    "https://applications.whitesands.org.ng/whitesandsback/apis/user/set-new-password",
    handleSetNewPasswordSuccess
  );

  const handleSetNewPassword = async (event, reset_code) => {
    console.log(reset_code);
    event.preventDefault();
    if (newPassword !== confirmNewPassword) {
      setResetPassError("Passwords do not match");
      return;
    } else {
      const dataToSend = {
        newPassword: newPassword,
        resetCode: reset_code,
      };
      try {
        await handleSetNewPasswordMutation.mutateAsync(
          JSON.stringify(dataToSend)
        );
      } catch (err) {
        alert(err);
      }
    }
  };

  //Fetch Applicant Data Functionality

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  };

  const handleFetchApplicantDataSuccess = (data) => {
    if (data.success) {
      setApplicantData(data.data);
      setApplicationStatus(data.data.applicationStatus);
    }
  };

  const fetchApplicantDataMutation = useGetData(
    "https://applications.whitesands.org.ng/whitesandsback/apis/user/getuserdata",
    handleFetchApplicantDataSuccess,
    config.headers
  );

  async function fetchApplicantData() {
    try {
      await fetchApplicantDataMutation.mutateAsync();
    } catch (error) {
      console.error("Error fetching applicant data:", error);
    }
  }

  //Application Form Submission Functionality

  const handleApplicationFormSubmitSuccess = (data) => {
    if (data.success) {
      setShowSubmitModal("applicationSubmitted");
    }
  };

  const handleApplicationFormSubmitMutation = usePostData(
    "https://applications.whitesands.org.ng/whitesandsback/apis/user/applicationform",
    handleApplicationFormSubmitSuccess,
    config.headers
  );

  const handleApplicationFormSubmit = async (event, formData) => {
    event.preventDefault();
    let isFormValid = true;

    if (!isFormValid) {
      console.log("Form Error");
      return;
    }

    const applyFormDataToSubmit = new FormData();
    for (const key in formData) {
      applyFormDataToSubmit.append(key, formData[key]);
    }

    try {
      await handleApplicationFormSubmitMutation.mutateAsync(
        applyFormDataToSubmit
      );
    } catch (err) {
      console.log("An Error Occured while trying to submit form data");
    }
  };

  // Document Submission Functionalities
  const handleApplicantDocumentSubmitSuccess = (data) => {
    if (data.success) {
      setDocumentSubmitted(true);
      setSelectedFiles([]);
      fetchUploadedFiles();

      // Set documentSubmitted back to false after 3 seconds
      setTimeout(() => {
        setDocumentSubmitted(false);
      }, 3000); // 3000 milliseconds = 3 seconds
    }
  };

  const handleApplicantDocumentSubmitMutation = usePostData(
    "https://applications.whitesands.org.ng/whitesandsback/apis/user/uploaddocuments",
    handleApplicantDocumentSubmitSuccess,
    config.headers
  );

  const handleApplicantDocumentSubmit = async (documentFileValue) => {
    // Define the maximum file size (25MB in bytes)
    const maxFileSize = 25 * 1024 * 1024;

    // Filter out files that exceed the size limit
    const invalidFiles = selectedFiles.filter(
      (file) => file.size > maxFileSize
    );

    if (invalidFiles.length > 0) {
      console.error("File size exceeds the limit.");
      return;
    }

    const fileData = new FormData();

    selectedFiles.forEach((file) => {
      fileData.append("documents[]", file); // Use 'documents[]' to match the backend structure
    });
    fileData.append("documentFileValue", documentFileValue);
    try {
      // Send a POST request with Axios, sending file data as raw binary
      await handleApplicantDocumentSubmitMutation.mutateAsync(fileData);
    } catch (err) {
      console.error("An error occurred:", err);
    }
  };

  const fetchUploadedFilesSuccess = (data) => {
    if (data.success) {
      // Compare the current uploadedFileNames with the new data
      const newDataString = JSON.stringify(data.data);
      const currentDataString = JSON.stringify(uploadedFileNames);

      if (newDataString !== currentDataString) {
        setUploadedFileNames(data.data);
      }
      setSelectedFiles([]);
      setDocumentFileValue("");
    }
  };

  const fetchUploadedFilesMutation = useGetData(
    "https://applications.whitesands.org.ng/whitesandsback/apis/user/checkuploaded",
    fetchUploadedFilesSuccess,
    config.headers
  );

  const fetchUploadedFiles = async () => {
    try {
      await fetchUploadedFilesMutation.mutateAsync();
    } catch (error) {
      console.error("Error fetching uploaded files:", error);
    }
  };

  //Logout Functionalities

  const handleLogout = async () => {
    // Clear access token and refresh token from local storage
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setApplicantData([]);
    setApplicationStatus("");

    navigate("/login");
    window.location.reload(true);
  };

  return (
    <UserDataContext.Provider
      value={{
        registerInfo,
        setRegisterInfo,
        registerError,
        setRegisterError,
        handleRegisterSubmit,
        registerLoading: registerMutation.isLoading,
        registerMutationError: registerMutation.isError,
        registrationFailed,
        setRegistrationFailed,
        newlyRegistered,
        applicantData,
        loginInfo,
        setLoginInfo,
        loginError,
        setLoginError,
        handleLoginSubmit,
        loginLoading: loginMutation.isLoading,
        loginMutationError: loginMutation.isError,
        loginFailed,
        setLoginFailed,
        handleResetPasswordRequest,
        resetPasswordRequestLoading: resetPasswordRequestMutation.isLoading,
        resetEmail,
        setResetEmail,
        resetLinkSent,
        setResetLinkSent,
        verifyResetToken,
        resetTokenVerified,
        setResetTokenVerified,
        newPassword,
        setNewPassword,
        confirmNewPassword,
        setconfirmNewPassword,
        resetPassError,
        setResetPassError,
        handleSetNewPassword,
        resetSuccessful,
        setResetSuccessful,
        sidebarContentSelect,
        setsidebarContentSelect,
        fetchApplicantData,
        fetchApplicantDataLoading: fetchApplicantDataMutation.isLoading,
        fetchApplicantDataError: fetchApplicantDataMutation.isError,
        applicationStatus,
        setApplicationStatus,
        checkTokenExpiration,
        checkTokenLoading: checkTokenMutation.isLoading,
        checkTokenError: checkTokenMutation.isError,
        handleApplicationFormSubmit,
        handleApplicationFormSubmitLoading:
          handleApplicationFormSubmitMutation.isLoading,
        handleApplicationFormSubmitError:
          handleApplicationFormSubmitMutation.isError,
        showSubmitModal,
        setShowSubmitModal,
        handleLogout,
        selectedFiles,
        setSelectedFiles,
        handleApplicantDocumentSubmit,
        handleApplicantDocumentSubmitLoading:
          handleApplicantDocumentSubmitMutation.isLoading,
        handleApplicantDocumentSubmitError:
          handleApplicantDocumentSubmitMutation.isError,
        documentSubmitted,
        setDocumentSubmitted,
        uploadedFileNames,
        fetchUploadedFiles,
        documentFileValue,
        setDocumentFileValue,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
}

export default UserDataContext;
