import React, { useContext } from "react";
import { capitalizeString } from "../utils/CapitalizeString";
import { Link } from "react-router-dom";
import UserDataContext from "../context/UserDataContext";

const DashboardInfo = ({ handleClick }) => {
  const { applicantData, sidebarContentSelect, setsidebarContentSelect } =
    useContext(UserDataContext);
  return (
    <div className="row mt-4">
      <div className="col-xl-3 col-md-6 my-2">
        <div className="customCard userHomeStatus">
          <div className="d-flex flex-column align-items-start h-100">
            <h6 className="mt-2 title">Application Status</h6>
            <div className="mt-5">
              <p className="mb-0 status">
                {applicantData.applicationStatus === ""
                  ? "Apply Now"
                  : applicantData.applicationStatus === "applied"
                  ? "Under review"
                  : applicantData.applicationStatus === "pending_exam"
                  ? "Pending Exam"
                  : capitalizeString(applicantData.applicationStatus)}
              </p>
              {applicantData.applicationStatus === "applied" && (
                <React.Fragment>
                  <div
                    style={{
                      textDecoration: "underline",
                      color: "#4fadea",
                      marginTop: "1rem",
                    }}
                  >
                    You will be Notified via Email
                  </div>
                </React.Fragment>
              )}
              {applicantData.applicationStatus === "accepted" && (
                <React.Fragment>
                  <div
                    onClick={() => setsidebarContentSelect("docupload")}
                    style={{
                      textDecoration: "underline",
                      color: "#4fadea",
                      marginTop: "1rem",
                      cursor: "pointer",
                    }}
                  >
                    Upload Documents Now
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      {applicantData.applicationStatus === "pending_exam" && (
        <React.Fragment>
          <div className="col-xl-3 col-md-6 my-2">
            <div className="customCard userHomeStatus">
              <div className="d-flex flex-column align-items-start h-100">
                <h6 className="mt-2 title">Exam Date</h6>
                <div className="mt-5">
                  <p className="mb-0 status">
                    {applicantData.examination[0].examDate}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 my-2">
            <div className="customCard userHomeStatus">
              <div className="d-flex flex-column align-items-start h-100">
                <h6 className="mt-2 title">Exam Venue</h6>
                <div className="mt-2">
                  <p className="mb-0 status" style={{ fontSize: "12px" }}>
                    {applicantData.examination[0].examVenue}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 my-2">
            <div className="customCard userHomeStatus">
              <div className="d-flex flex-column align-items-start h-100">
                <h6 className="mt-2 title">Exam Time</h6>
                <div className="mt-2">
                  <p className="mb-0 status" style={{ fontSize: "14px" }}>
                    Start Time:{" "}
                    <span> {applicantData.examination[0].examStart}</span>
                  </p>
                  <p className="mb-0 status" style={{ fontSize: "14px" }}>
                    End Time:{" "}
                    <span> {applicantData.examination[0].examStop}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default DashboardInfo;
