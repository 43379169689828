import { useMutation, useQueryClient } from "react-query";

const usePostData = (url, onSuccessCallback, headers, onErrorCallback) => {
  const queryClient = useQueryClient();

  const postDataMutation = useMutation(
    (bodyData) =>
      fetch(url, {
        method: "POST",
        body: bodyData,
        headers: headers,
      }),
    {
      // Optional: You can handle query invalidation, onSuccess, onError, etc.
      // For example, you can invalidate a query after the mutation is successful.
      onSuccess: async (response) => {
        const data = await response.json();
        if (onSuccessCallback) {
          onSuccessCallback(data);
        }
        queryClient.invalidateQueries("yourQueryKey");
      },
      onError: (error) => {
        if (onErrorCallback) {
          onErrorCallback(error);
        }
        queryClient.invalidateQueries("yourQueryKey");
      },
    }
  );

  return postDataMutation;
};

export default usePostData;
