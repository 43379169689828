import React, { useContext, useEffect, useState } from "react";
import logo from "../assets/images/whitesandsschoollogo.png";
import { useLocation } from "react-router-dom";
import UserDataContext from "../context/UserDataContext";

const ResetPassConfirm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const emailAddress = queryParams.get("email");
  const reset_code = queryParams.get("token");
  const {
    resetTokenVerified,
    newPassword,
    setNewPassword,
    confirmNewPassword,
    setconfirmNewPassword,
    resetSuccessful,
    verifyResetToken,
    resetPassError,
    handleSetNewPassword,
  } = useContext(UserDataContext);

  useEffect(() => {
    verifyResetToken(emailAddress, reset_code);
  }, []);
  return (
    <React.Fragment>
      {resetTokenVerified && (
        <div>
          {resetSuccessful ? (
            <p>
              Password reset successful. You can now log in with your new
              password.
            </p>
          ) : (
            <div className="log-reg">
              <div className="container-fluid" style={{ minHeight: "100vh" }}>
                <div className="row logreg-row" style={{ minHeight: "100vh" }}>
                  <div className="col-xl-6 col-lg-5 col-sm-8 col-xs-12 mt-5">
                    <div className="logreg-form d-flex flex-column align-items-center justify-content-between w-100">
                      <div className="mb-5 align-self-start">
                        <img
                          className="schLogo"
                          src={logo}
                          alt="Whitesands School Logo"
                        />
                      </div>
                      <form
                        method="post"
                        className="px-4 w-100"
                        onSubmit={(event) =>
                          handleSetNewPassword(event, reset_code)
                        }
                      >
                        <h3 className="text-start display-4 mb-2 welcomeTxt">
                          Welcome!
                        </h3>
                        <p className="loginCtaTitle text-secondary">
                          Reset Your Password
                        </p>
                        {resetPassError !== "" && (
                          <p className="text-danger">{resetPassError}</p>
                        )}
                        <div className="form-group my-4">
                          <div className="ml-5"></div>
                          <div className="d-flex">
                            <div>
                              <label htmlFor="emailAddress">New Password</label>
                              <p className="mb-1">
                                <small className="text-secondary">
                                  Enter your new password
                                </small>
                              </p>
                            </div>
                          </div>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text h-100 position-absolute"
                                style={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                  zIndex: 10,
                                }}
                              >
                                <i className="">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M11.1094 5.12934C9.52954 5.48001 8.35396 6.55509 7.85085 8.10937C7.74267 8.44354 7.71815 8.61567 7.69875 9.17662C7.67432 9.88293 7.71187 10.1984 7.884 10.7344C8.28407 11.9801 9.31223 13.0333 10.5467 13.4619C11.4323 13.7695 12.568 13.7694 13.4541 13.4616C14.6518 13.0458 15.6709 12.0265 16.0869 10.8283C16.3945 9.94265 16.3944 8.80701 16.0866 7.92084C15.5974 6.51178 14.3749 5.44945 12.8758 5.13046C12.4898 5.04834 11.4772 5.04768 11.1094 5.12934ZM11.6399 6.2429C10.4523 6.36703 9.40799 7.21424 8.99245 8.39062C8.89917 8.65476 8.88482 8.78582 8.88482 9.37499C8.88482 9.96417 8.89917 10.0952 8.99245 10.3594C9.6734 12.2871 11.8742 13.1087 13.6128 12.0841C14.2328 11.7187 14.765 11.059 15.004 10.3594C15.1469 9.94073 15.1806 9.10809 15.073 8.64843C14.7115 7.10353 13.244 6.07523 11.6399 6.2429ZM10.7127 15.2594C8.27957 15.652 6.19612 16.9852 4.84387 19.015C4.6523 19.3026 4.48397 19.6094 4.46976 19.6969C4.41637 20.0258 4.7055 20.39 5.02036 20.3904C5.32167 20.3909 5.42704 20.3046 5.78376 19.7648C6.4065 18.8226 7.06964 18.149 7.94531 17.569C11.0901 15.4861 15.3029 16.1266 17.7164 19.0547C17.8651 19.2351 18.1076 19.5681 18.2551 19.7945C18.5602 20.2626 18.6892 20.3672 18.9609 20.3672C19.3193 20.3672 19.5877 20.0512 19.5302 19.6969C19.4963 19.488 18.9012 18.5991 18.4498 18.083C17.2431 16.7033 15.4945 15.6965 13.6584 15.3242C12.9956 15.1898 11.366 15.1539 10.7127 15.2594Z"
                                      fill="#828282"
                                    />
                                  </svg>{" "}
                                </i>
                              </span>
                            </div>
                            <input
                              id="newPass"
                              type="password"
                              name="newPass"
                              className="form-control px-5 inputElement"
                              value={newPassword}
                              onChange={(event) =>
                                setNewPassword(event.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group my-4">
                          <div className="ml-5"></div>
                          <div className="d-flex">
                            <div>
                              <label htmlFor="emailAddress">
                                Confirm New Password
                              </label>
                              <p className="mb-1">
                                <small className="text-secondary">
                                  Enter your new password
                                </small>
                              </p>
                            </div>
                          </div>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text h-100 position-absolute"
                                style={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                  zIndex: 10,
                                }}
                              >
                                <i className="">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M11.1094 5.12934C9.52954 5.48001 8.35396 6.55509 7.85085 8.10937C7.74267 8.44354 7.71815 8.61567 7.69875 9.17662C7.67432 9.88293 7.71187 10.1984 7.884 10.7344C8.28407 11.9801 9.31223 13.0333 10.5467 13.4619C11.4323 13.7695 12.568 13.7694 13.4541 13.4616C14.6518 13.0458 15.6709 12.0265 16.0869 10.8283C16.3945 9.94265 16.3944 8.80701 16.0866 7.92084C15.5974 6.51178 14.3749 5.44945 12.8758 5.13046C12.4898 5.04834 11.4772 5.04768 11.1094 5.12934ZM11.6399 6.2429C10.4523 6.36703 9.40799 7.21424 8.99245 8.39062C8.89917 8.65476 8.88482 8.78582 8.88482 9.37499C8.88482 9.96417 8.89917 10.0952 8.99245 10.3594C9.6734 12.2871 11.8742 13.1087 13.6128 12.0841C14.2328 11.7187 14.765 11.059 15.004 10.3594C15.1469 9.94073 15.1806 9.10809 15.073 8.64843C14.7115 7.10353 13.244 6.07523 11.6399 6.2429ZM10.7127 15.2594C8.27957 15.652 6.19612 16.9852 4.84387 19.015C4.6523 19.3026 4.48397 19.6094 4.46976 19.6969C4.41637 20.0258 4.7055 20.39 5.02036 20.3904C5.32167 20.3909 5.42704 20.3046 5.78376 19.7648C6.4065 18.8226 7.06964 18.149 7.94531 17.569C11.0901 15.4861 15.3029 16.1266 17.7164 19.0547C17.8651 19.2351 18.1076 19.5681 18.2551 19.7945C18.5602 20.2626 18.6892 20.3672 18.9609 20.3672C19.3193 20.3672 19.5877 20.0512 19.5302 19.6969C19.4963 19.488 18.9012 18.5991 18.4498 18.083C17.2431 16.7033 15.4945 15.6965 13.6584 15.3242C12.9956 15.1898 11.366 15.1539 10.7127 15.2594Z"
                                      fill="#828282"
                                    />
                                  </svg>{" "}
                                </i>
                              </span>
                            </div>
                            <input
                              id="newPassConfirm"
                              type="password"
                              name="newPassConfirm"
                              className="form-control px-5 inputElement"
                              value={confirmNewPassword}
                              onChange={(event) =>
                                setconfirmNewPassword(event.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <input
                            type="submit"
                            name="btnSubmit"
                            className="btnLogin"
                            value="Submit"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default ResetPassConfirm;
