import { useContext } from "react";
import UserDataContext from "../../context/UserDataContext";
import ModalComponent from "../Modals/ModalComponent";
import axios from "axios";

const Apply9 = ({
  goToPreviousPage,
  formData,
  setFormData,
  formError,
  setFormError,
  showSubmitModal,
  setShowSubmitModal,
  handleClose,
  handleShow,
}) => {
  const { handleApplicationFormSubmit } = useContext(UserDataContext);

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setFormError({
      ...formError,
      [event.target.name]: event.target.value ? "" : "This Field is required",
    });
    console.log(formData);
  };

  // const handleApplicationFormSubmit = async (event) => {
  //   event.preventDefault();
  //   let isFormValid = true;

  //   if (!isFormValid) {
  //     console.log("Form Error");
  //     return;
  //   }

  //   const applyFormDataToSubmit = new FormData();
  //   for (const key in formData) {
  //     applyFormDataToSubmit.append(key, formData[key]);
  //   }

  //   const url = "http://localhost:8080/whitesandsback/user/applicationform";

  //   try {
  //     const response = await axios.post(url, applyFormDataToSubmit, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //       },
  //     });
  //     console.log(response);
  //     if (response.data.success === true) {
  //       setShowSubmitModal(true);
  //     }
  //   } catch (err) {
  //     console.log("An Error Occured while trying to submit form data");
  //   }
  // };

  return (
    <div>
      <div className="row mt-1">
        <div className="col-xl-4 col-md-6 my-2">
          <div className="customCard userHomeStatus">
            <div className="d-flex flex-column align-items-start">
              <h6 className="mt-2 title">NEW APPLICATION</h6>
              <div className="mt-4 w-100">
                <form
                  action=""
                  onSubmit={(event) =>
                    handleApplicationFormSubmit(event, formData)
                  }
                >
                  <div className="form-group mb-2">
                    <label htmlFor="referralName" className="mb-2">
                      Referral Name{" "}
                      {formError.referralName && (
                        <small className="px-1 py-2 text-danger">
                          {formError.referralName}
                        </small>
                      )}
                    </label>
                    <div className="input-group">
                      <input
                        id="referralName"
                        type="text"
                        name="referralName"
                        className="w-100 px-3 py-1 inputElement bg-white"
                        value={formData.referralName}
                        onChange={(event) => handleInputChange(event)}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-2">
                    <label htmlFor="referralEmail" className="mb-2">
                      Referral Email address{" "}
                      {formError.referralEmail && (
                        <small className="px-1 py-2 text-danger">
                          {formError.referralEmail}
                        </small>
                      )}
                    </label>
                    <div className="input-group">
                      <input
                        id="referralEmail"
                        type="text"
                        name="referralEmail"
                        className="py-1 w-100 px-2 inputElement bg-white"
                        value={formData.referralEmail}
                        onChange={(event) => handleInputChange(event)}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-2">
                    <label htmlFor="referralPhoneNumber" className="mb-2">
                      Referral Phone number{" "}
                      {formError.referralPhoneNumber && (
                        <small className="px-1 py-2 text-danger">
                          {formError.referralPhoneNumber}
                        </small>
                      )}
                    </label>
                    <div className="input-group">
                      <input
                        id="referralPhoneNumber"
                        type="tel"
                        name="referralPhoneNumber"
                        className="py-1 w-100 px-2 inputElement bg-white"
                        value={formData.referralPhoneNumber}
                        onChange={(event) => handleInputChange(event)}
                      />
                    </div>
                  </div>

                  <div className="d-flex justify-content-between">
                    <div className="form-group mt-2">
                      <input
                        type="button"
                        name="btnPrev"
                        className="btnPrev"
                        value="Back"
                        onClick={goToPreviousPage}
                      />
                    </div>
                    <div className="form-group mt-2">
                      <input
                        type="submit"
                        name="btnNext"
                        className="btnNext"
                        value="Submit"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSubmitModal && (
        <ModalComponent
          showSubmitModal={showSubmitModal}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

export default Apply9;
