import React from "react";
import { Modal } from "react-bootstrap";

const ModalComponent = ({ showSubmitModal, handleClose }) => {
  return (
    <React.Fragment>
      {showSubmitModal === "applicationSubmitted" && (
        <Modal
          className="justify-content-center h-100"
          show={showSubmitModal === "applicationSubmitted"}
          onHide={handleClose}
          centered
        >
          <Modal.Header>
            <Modal.Title style={{ width: "100%" }}>
              <h4 style={{ textAlign: "center" }}>Application Submitted</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <i className="d-flex align-items-center justify-content-center mb-3">
              <svg
                width="84"
                height="63"
                viewBox="0 0 84 63"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 34.18L28.7651 63L83.9996 7.82015L76.0698 0L28.7651 47.2497L7.81999 26.3047L0 34.18Z"
                  fill="#3FC59D"
                />
              </svg>
            </i>
            <p className="text-black text-center">
              Your Application has been submitted
            </p>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <button className="btnNext" onClick={handleClose}>
              Dismiss
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default ModalComponent;
