import { Link } from "react-router-dom";

const AdminSideBar = ({ handleClick, sidebarContentSelect }) => {
  return (
    <aside className="userHomeSideNav">
      <div className="sideNavInner">
        <ul>
          <li
            className={
              sidebarContentSelect === "dashboard" ? "active mb-3" : "mb-3"
            }
          >
            <Link
              className="text-decoration-none mb-4 dashboard"
              onClick={() => handleClick("dashboard")}
            >
              <div className="d-flex align-items-center">
                <span className="d-flex align-items-center justify-content-center optionIcon mx-2">
                  <svg
                    width="18"
                    height="20"
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20ZM16 18V2H2V18H16ZM5 5H13V7H5V5ZM5 9H13V11H5V9ZM5 13H10V15H5V13Z"
                      fill="white"
                    ></path>
                  </svg>
                </span>
                <p className="text-decoration-none text-white mb-0">
                  Dashboard
                </p>
              </div>
            </Link>
          </li>
          <li
            className={
              sidebarContentSelect === "examination" ? "active mb-3" : "mb-3"
            }
          >
            <Link
              className="text-decoration-none mb-4 dashboard"
              onClick={() => handleClick("examination")}
            >
              <div className="d-flex align-items-center">
                <span className="d-flex align-items-center justify-content-center optionIcon mx-2">
                  <svg
                    width="18"
                    height="20"
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20ZM16 18V2H2V18H16ZM5 5H13V7H5V5ZM5 9H13V11H5V9ZM5 13H10V15H5V13Z"
                      fill="white"
                    ></path>
                  </svg>
                </span>
                <p className="text-decoration-none text-white mb-0">
                  Examination
                </p>
              </div>
            </Link>
          </li>
          <li
            className={
              sidebarContentSelect === "interview" ? "active mb-3" : "mb-3"
            }
          >
            <Link
              className="text-decoration-none mb-4 dashboard"
              onClick={() => handleClick("interview")}
            >
              <div className="d-flex align-items-center">
                <span className="d-flex align-items-center justify-content-center optionIcon mx-2">
                  <svg
                    width="18"
                    height="20"
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20ZM16 18V2H2V18H16ZM5 5H13V7H5V5ZM5 9H13V11H5V9ZM5 13H10V15H5V13Z"
                      fill="white"
                    ></path>
                  </svg>
                </span>
                <p className="text-decoration-none text-white mb-0">
                  Interview
                </p>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default AdminSideBar;
