import React, { useContext } from "react";
import AdminDataContext from "../context/AdminDataContext";
import { AiOutlineSearch } from "react-icons/ai";
import CustomSelect from "./CustomSelect";
import AdminSearchComponent from "./AdminSearchComponent";
import { Dropdown, Nav } from "react-bootstrap";

const AdminExaminationTable = ({
  selectedOption,
  setSelectedOption,
  handleExamEvent,
  handleBulkExamEvent,
  handleRowClick,
  toggleCheckBox,
  handleCellClick,
  handlePageChange,
  handleInterviewEvent,
}) => {
  const {
    applicantsData,
    handleBulkAction,
    handlePassOrFailExam,
    filteredApplicantsData,
    setFilteredApplicantsData,
    currentApplicantsPage,
    setCurrentApplicantsPage,
    applicantsPageSize,
    bulkOptions,
    setBulkOption,
    allowBulkActions,
    setAllowBulkAction,
  } = useContext(AdminDataContext);

  const startIndex = (currentApplicantsPage - 1) * applicantsPageSize;
  const endIndex = startIndex + applicantsPageSize;

  const paginatedExaminationData = filteredApplicantsData
    .filter(
      (data) =>
        data.applicationStatus === "pending_exam" ||
        data.applicationStatus === "passed_exam" ||
        data.applicationStatus === "failed_exam"
    )
    .slice(startIndex, endIndex);

  const totalPages = Math.ceil(
    paginatedExaminationData.length / applicantsPageSize
  );

  const handleExamCandidatDataFilter = (event) => {
    const { value } = event.target;

    if (value === "pending_exam") {
      setAllowBulkAction("pending_exam_actions");
      setBulkOption([
        { value: "pass_exams", label: "Pass Exams" },
        { value: "fail_exams", label: "Fail Exams" },
      ]);
      setFilteredApplicantsData(
        applicantsData.filter(
          (data) => data.applicationStatus === "pending_exam"
        )
      );
    } else if (value === "passed_exam") {
      setAllowBulkAction("passed_exam_actions");
      setBulkOption([]);
      setFilteredApplicantsData(
        applicantsData.filter(
          (data) => data.applicationStatus === "passed_exam"
        )
      );
    } else if (value === "failed_exam") {
      setAllowBulkAction("failed_exam_actions");
      setBulkOption([]);
      setFilteredApplicantsData(
        applicantsData.filter(
          (data) => data.applicationStatus === "failed_exam"
        )
      );
    } else if (value === "all_exam_candidate") {
      setAllowBulkAction("");
      setBulkOption([]);
      // Default case when no filter is selected
      setFilteredApplicantsData(applicantsData);
    }

    // Reset to the first page whenever the filter changes
    setCurrentApplicantsPage(1);
  };
  return (
    <main className="mainContent">
      <div className="container-fluid">
        <AdminSearchComponent />
        <div>
          <div className="row mt-1">
            <div className="col-12 my-2">
              <div className="customCard" style={{ zIndex: "-1" }}>
                <div>
                  <h6 className="mt-2 title">Exam Candidates</h6>
                  <div className="mt-4 w-100">
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{ gap: "10px" }}
                    >
                      {allowBulkActions !== "" && (
                        <div className="d-flex">
                          <CustomSelect
                            options={bulkOptions}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                          />
                          <div className="mb-2 px-2">
                            <button
                              className=""
                              style={{
                                padding: "0.7rem 2.5rem",
                                backgroundColor: "#4FADEA",
                                border: "none",
                                borderRadius: "25px",
                                color: "#fff",
                              }}
                              onClick={() => {
                                selectedOption.value === "send_exam"
                                  ? handleBulkExamEvent("send_bulk_exam")
                                  : handleBulkAction(selectedOption.value);
                              }}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      )}

                      <div>
                        <select
                          defaultValue="all_exam_candidate"
                          onChange={(event) =>
                            handleExamCandidatDataFilter(event)
                          }
                        >
                          <option value="all_exam_candidate">
                            All Candidates
                          </option>
                          <option value="pending_exam">Pending Exam</option>
                          <option value="passed_exam">Passed Candidates</option>
                          <option value="failed_exam">Failed Candidates</option>
                        </select>
                      </div>
                    </div>
                    <div className="applicants-table">
                      <table className="table">
                        <thead>
                          <tr>
                            <th style={{ width: "1rem" }}></th>
                            <th>Unique Application Code</th>
                            <th>Applicant Name</th>
                            <th>DOB</th>
                            <th>Father name</th>
                            <th>Mother Name</th>
                            <th>Class</th>
                            <th>Age</th>
                            <th>Religion</th>
                            <th>Nationality</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {paginatedExaminationData?.map((data) => (
                            <tr
                              style={{
                                backgroundColor:
                                  data.applicationStatus === "pending_exam" &&
                                  data.documents.length === 0
                                    ? "#FFFFFF"
                                    : data.applicationStatus ===
                                        "passed_exam" &&
                                      data.documents.length > 0
                                    ? "#C8E6C9"
                                    : data.applicationStatus === "failed_exam"
                                    ? "#FFCDD2"
                                    : "#FFFFFF",
                              }}
                              key={data.applicationId}
                              onClick={() => handleRowClick(data)}
                            >
                              <td>
                                {allowBulkActions !== "" && (
                                  <input
                                    type="checkbox"
                                    name=""
                                    id=""
                                    onChange={(event) =>
                                      toggleCheckBox(event, data)
                                    }
                                    onClick={(event) => event.stopPropagation()}
                                  />
                                )}
                              </td>
                              <td>{data.applicationId}</td>
                              <td>{`${data.applicantSurname} ${data.applicantFirstname}`}</td>
                              <td>{data.applicantDob}</td>
                              <td>
                                {`${data.fatherSurname} ${data.fatherFirstname}`}
                              </td>
                              <td>
                                {`${data.motherSurname} ${data.motherFirstname}`}
                              </td>
                              <td>{data.applicantPresentClass}</td>
                              <td>9</td>
                              <td>
                                {data.applicantReligion === "other"
                                  ? data.applicantOtherReligion
                                  : data.applicantReligion}
                              </td>
                              <td>
                                {data.applicantNationality === "other"
                                  ? data.otherNationality
                                  : data.applicantNationality}
                              </td>
                              <td onClick={(event) => handleCellClick(event)}>
                                <Dropdown
                                  style={{
                                    backgroundColor: "transparent",
                                    borderRadius: "6px",
                                  }}
                                >
                                  <Dropdown.Toggle
                                    as={Nav.Link}
                                    className="bg-transparent border-0 user"
                                    variant="user"
                                  >
                                    <svg
                                      width="25"
                                      height="5"
                                      viewBox="0 0 25 5"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <circle
                                        cx="2.5"
                                        cy="2.5"
                                        r="2.5"
                                        fill="#000"
                                      ></circle>
                                      <circle
                                        cx="12.5"
                                        cy="2.5"
                                        r="2.5"
                                        fill="#000"
                                      ></circle>
                                      <circle
                                        cx="22.5"
                                        cy="2.5"
                                        r="2.5"
                                        fill="#000"
                                      ></circle>
                                    </svg>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {data.applicationStatus ===
                                      "pending_exam" && (
                                      <React.Fragment>
                                        <Dropdown.Item
                                          onClick={() =>
                                            handlePassOrFailExam(
                                              "pass_exams",
                                              data.userId,
                                              data.applicantTitle,
                                              data.applicantSurname,
                                              data.applicantFirstname,
                                              data.applicantDob,
                                              data.applicantEmail,
                                              data.applicationId
                                            )
                                          }
                                        >
                                          Pass Exam
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() =>
                                            handlePassOrFailExam(
                                              "fail_exams",
                                              data.userId,
                                              data.applicantTitle,
                                              data.applicantSurname,
                                              data.applicantFirstname,
                                              data.applicantDob,
                                              data.applicantEmail,
                                              data.applicationId
                                            )
                                          }
                                        >
                                          Fail Exam
                                        </Dropdown.Item>
                                      </React.Fragment>
                                    )}

                                    {data.applicationStatus ===
                                      "exam_passed" && (
                                      <Dropdown.Item
                                        onClick={() => handleInterviewEvent()}
                                      >
                                        Invite for interview
                                      </Dropdown.Item>
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="pagination-container w-100 d-flex justify-content-end mt-2 ml-auto">
                        <div className="pagination ">
                          <button
                            type="button"
                            onClick={() =>
                              handlePageChange(currentApplicantsPage - 1)
                            }
                            disabled={currentApplicantsPage === 1}
                          >
                            Previous
                          </button>
                          <span>{currentApplicantsPage}</span>
                          <button
                            type="button"
                            onClick={() =>
                              handlePageChange(currentApplicantsPage + 1)
                            }
                            disabled={currentApplicantsPage === totalPages}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AdminExaminationTable;
