import React, { useContext } from "react";
import UserDataContext from "../../context/UserDataContext";

const Apply1 = ({
  goToNextPage,
  formData,
  setFormData,
  formError,
  setFormError,
  disableNext,
  setDisableNext,
}) => {
  const { applicantData } = useContext(UserDataContext);

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setFormError({
      ...formError,
      [event.target.name]: event.target.value ? "" : "This Field is required",
    });
  };

  const handleNextClick = (event) => {
    event.preventDefault();
    let isFormValid = true;
    setFormError({});
    if (!formData.applicantTitle) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        applicantTitle: "Title is required",
      }));

      isFormValid = false;
    }
    if (!formData.whoFillForm) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        whoFillForm: "Who filled form is required",
      }));

      isFormValid = false;
    }

    if (!isFormValid) {
      console.log("Form Error Detected");
      return;
    }

    goToNextPage();
  };
  return (
    <div className="row mt-4">
      <div className="col-xl-4 col-lg-6 col-md-10 my-2">
        <div className="customCard userHomeStatus">
          <div className="d-flex flex-column align-items-start">
            <h6 className="mt-2 title">NEW APPLICATION</h6>
            <div className="mt-4 w-100">
              <form action="">
                <div className="form-group d-flex flex-column align-items-start justify-content-between mb-4">
                  <label htmlFor="applicantTitle" className="mb-2">
                    Title{" "}
                    {formError.applicantTitle && (
                      <small className="px-1 py-2 text-danger">
                        {formError.applicantTitle}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <select
                      className="w-100 inputElement px-2 py-2"
                      name="applicantTitle"
                      id="applicantTitle"
                      defaultValue={formData.applicantTitle}
                      onChange={(event) => handleInputChange(event)}
                    >
                      <option value="">Select Title</option>
                      <option value="mr">Mr</option>
                      <option value="mrs">Mrs</option>
                      <option value="master">Master</option>
                      <option value="miss">Miss</option>
                    </select>
                    <div className="input-group-postpend">
                      <span
                        className="input-group-text h-100 position-absolute"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          zIndex: 10,
                          transform: "translateX(-1.75rem)",
                        }}
                      >
                        <i>
                          <svg
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.2021 0.450301L7.75177 13.3546L0.301465 0.450301L15.2021 0.450301Z"
                              fill="#D9D9D9"
                            ></path>
                          </svg>
                        </i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group my-4">
                  <label htmlFor="userFullname" className="mb-2">
                    Name{" "}
                  </label>
                  <div className="input-group">
                    <input
                      id="userFullname"
                      type="text"
                      name="userFullname"
                      className="form-control px-3 inputElement bg-white"
                      value={`${applicantData.lastName} ${applicantData.firstName}`}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group d-flex flex-column align-items-start justify-content-between">
                  <label htmlFor="whoFillForm" className="mb-2">
                    Who is filling this form{" "}
                    {formError.whoFillForm && (
                      <small className="px-1 py-2 text-danger">
                        {formError.whoFillForm}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <select
                      className="w-100 inputElement px-2 py-2"
                      name="whoFillForm"
                      id="whoFillForm"
                      defaultValue={formData.whoFillForm}
                      onChange={(event) => handleInputChange(event)}
                    >
                      <option value="">Select</option>
                      <option value="parent">Parent</option>
                      <option value="student">Student(self)</option>
                    </select>
                    <div className="input-group-postpend">
                      <span
                        className="input-group-text h-100 position-absolute"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          zIndex: 10,
                          transform: "translateX(-1.75rem)",
                        }}
                      >
                        <i>
                          <svg
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.2021 0.450301L7.75177 13.3546L0.301465 0.450301L15.2021 0.450301Z"
                              fill="#D9D9D9"
                            ></path>
                          </svg>
                        </i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group my-4">
                  <label htmlFor="applicantEmail" className="mb-2">
                    Email{" "}
                    {formError.applicantEmail && (
                      <small className="px-1 py-2 text-danger">
                        {formError.applicantEmail}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <input
                      id="applicantEmail"
                      type="text"
                      name="applicantEmail"
                      className="form-control px-3 inputElement bg-white"
                      value={formData.applicantEmail}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <input
                    type="button"
                    name="btnNext"
                    className="btnNext"
                    value="Next"
                    disabled={disableNext}
                    onClick={handleNextClick}
                  />
                </div>
              </form>
              {disableNext && (
                <p className="text-danger py-2">
                  Unfortunately, we do not take in female students as it is a
                  boys only school. Please contact admissions@whitesands.org.ng
                  for more information.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Apply1;
