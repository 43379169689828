import React from "react";

const Apply2 = ({
  goToNextPage,
  goToPreviousPage,
  formData,
  setFormData,
  formError,
  setFormError,
  disableNext,
  setDisableNext,
}) => {
  const handlePhoneNumberChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, ""); // Remove non-numeric characters

    if (inputValue !== numericValue) {
      // Replace the input value with numeric characters only
      handleInputChange(event);
    }

    // Validate the length of the numeric input
    if (numericValue.length >= 10) {
    } else {
    }
  };
  const handleGenderChange = (event) => {
    const { value } = event.target;
    if (value === "female") {
      setDisableNext(true);
    } else {
      setDisableNext(false);
      handleInputChange(event);
    }
  };
  // const handleDobChange = (event) => {
  //   const enteredDate = new Date(event.target.value);
  //   const currentDate = new Date();

  //   // Calculate the age difference in years
  //   const ageDifference = currentDate.getFullYear() - enteredDate.getFullYear();

  //   // Check if the user is at least 10 years old
  //   if (ageDifference >= 10) {
  //     // Valid date of birth, you can proceed with your logic
  //     setDisableNext(false);
  //     handleInputChange(event);
  //     console.log("Valid date of birth");
  //   } else {
  //     // Invalid date of birth, show an error message or prevent further action
  //     setDisableNext(true);
  //     console.log("Invalid date of birth. User must be at least 10 years old.");
  //   }
  // };

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setFormError({
      ...formError,
      [event.target.name]: event.target.value ? "" : "This Field is required",
    });
  };
  const handleNextClick = (event) => {
    event.preventDefault();
    let isFormValid = true;
    if (!formData.applicantGender) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        applicantGender: "Gender is required",
      }));
      console.log("2");
      isFormValid = false;
    }
    if (!formData.applicantSurname) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        applicantSurname: "Surname is required",
      }));
      isFormValid = false;
    }
    if (!formData.applicantFirstname) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        applicantFirstname: "First Name is required",
      }));
      isFormValid = false;
    }
    if (!formData.applicantMiddlename) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        applicantMiddlename: "Middle Name is required",
      }));
      isFormValid = false;
    }
    if (!formData.applicantDob) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        applicantDob: "Date of Birth is required",
      }));
      isFormValid = false;
    }
    if (!formData.applicantPhonenumber) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        applicantPhonenumber: "Phone number is required",
      }));
      isFormValid = false;
    }

    if (!isFormValid) {
      console.log("Form Error Detected");
      return;
    }
    console.log(formData);
    goToNextPage();
  };
  return (
    <div className="row mt-4">
      <div className="col-xl-4 col-lg-6 col-md-10 my-2">
        <div className="customCard userHomeStatus">
          <div className="d-flex flex-column align-items-start">
            <h6 className="mt-2 title">NEW APPLICATION</h6>
            <div className="mt-4 w-100">
              <form action="">
                <div className="form-group d-flex flex-column align-items-start justify-content-between mb-4">
                  <label htmlFor="applicantGender" className="mb-2">
                    Applicant's Gender{" "}
                    {formError.applicantGender && (
                      <small className="px-1 py-2 text-danger">
                        {formError.applicantGender}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <select
                      className="w-100 inputElement px-2 py-2"
                      name="applicantGender"
                      id="applicantGender"
                      defaultValue={formData.applicantGender}
                      onChange={(event) => handleGenderChange(event)}
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    <div className="input-group-postpend">
                      <span
                        className="input-group-text h-100 position-absolute"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          zIndex: 10,
                          transform: "translateX(-1.75rem)",
                        }}
                      >
                        <i>
                          <svg
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.2021 0.450301L7.75177 13.3546L0.301465 0.450301L15.2021 0.450301Z"
                              fill="#D9D9D9"
                            ></path>
                          </svg>
                        </i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group my-4">
                  <label htmlFor="applicantSurname" className="mb-2">
                    Applicant's Surname{" "}
                    {formError.applicantSurname && (
                      <small className="px-1 py-2 text-danger">
                        {formError.applicantSurname}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <input
                      id="applicantSurname"
                      type="text"
                      name="applicantSurname"
                      className="form-control px-3 inputElement bg-white"
                      value={formData.applicantSurname}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </div>
                </div>
                <div className="form-group my-4">
                  <label htmlFor="applicantFirstname" className="mb-2">
                    Applicant's First Name{" "}
                    {formError.applicantFirstname && (
                      <small className="px-1 py-2 text-danger">
                        {formError.applicantFirstname}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <input
                      id="applicantFirstname"
                      type="text"
                      name="applicantFirstname"
                      className="form-control px-3 inputElement bg-white"
                      value={formData.applicantFirstname}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </div>
                </div>
                <div className="form-group my-4">
                  <label htmlFor="applicantMiddlename" className="mb-2">
                    Applicant's Middle Name{" "}
                    {formError.applicantMiddlename && (
                      <small className="px-1 py-2 text-danger">
                        {formError.applicantMiddlename}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <input
                      id="applicantMiddlename"
                      type="text"
                      name="applicantMiddlename"
                      className="form-control px-3 inputElement bg-white"
                      value={formData.applicantMiddlename}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </div>
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="applicantDob" className="mb-2">
                    Applicant's D.O.B{" "}
                    {formError.applicantDob && (
                      <small className="px-1 py-2 text-danger">
                        {formError.applicantDob}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <input
                      id="applicantDob"
                      type="date"
                      name="applicantDob"
                      className="py-1 w-100 px-3 inputElement bg-white"
                      value={formData.applicantDob}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </div>
                </div>
                <div className="form-group my-4">
                  <label htmlFor="applicantPhonenumber" className="mb-2">
                    Phone number{" "}
                    {formError.applicantPhonenumber && (
                      <small className="px-1 py-2 text-danger">
                        {formError.applicantPhonenumber}
                      </small>
                    )}
                  </label>
                  <div className="input-group">
                    <input
                      id="applicantPhonenumber"
                      type="number" // Ensure the input is of type number
                      inputMode="numeric"
                      name="applicantPhonenumber"
                      className="form-control px-3 inputElement bg-white applicant-phone"
                      value={formData.applicantPhonenumber || ""}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <div className="form-group mt-3">
                    <input
                      type="button"
                      name="btnPrev"
                      className="btnPrev"
                      value="Back"
                      onClick={goToPreviousPage}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <input
                      type="button"
                      name="btnNext"
                      className="btnNext"
                      value="Next"
                      disabled={disableNext}
                      onClick={(event) => handleNextClick(event)}
                    />
                  </div>
                </div>
              </form>
              {disableNext && (
                <p className="text-danger py-2">
                  Unfortunately, we do not take in students from Primary 4 and
                  below. Please contact admissions@whitesands.org.ng for more
                  information.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Apply2;
